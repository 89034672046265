import { TTaskRowProps } from "./types/TTaskRowProps";
import classes from "./LeftContainer.module.scss";
import { Box, Typography, useTheme } from "@mui/material";
import StateIcon from "../State/StateIcon";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import { link } from "../../../../utils/link";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";
import React from "react";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import { Tooltip } from "../../../../modules/materialUI";

const LeftContainer = (props: TTaskRowProps) => {
  const theme = useTheme();
  const { openTaskViewHandler } = useTaskViewHandler({
    taskRef: props.task.ref,
    segmentNumber: props.task.number,
  });

  const taskHandler = () => {
    openTaskViewHandler();
  };

  const anchorHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    link.anchorClickHandler(e);
  };

  const state = props.isSegmentView ? props.task.state : props.task.aggState;
  const statusType = props.isSegmentView
    ? props.task.statusType
    : props.task.aggStatus;
  const taskUrl = taskUtil.urlByRef(
    props.task.ref,
    props.isSegmentView ? props.task.number : undefined
  );

  return (
    <div className={classes.container}>
      <div>
        {state || statusType !== AggStatusEnum.TO_DO ? (
          <StateIcon
            className={classes["state-icon"]}
            state={state}
            statusType={statusType}
            status={
              props.isSegmentView || props.task.segmentCount === 1
                ? props.task.status
                : props.task.aggStatus
            }
          />
        ) : (
          <Box style={{ width: "1.1rem" }} />
        )}
      </div>

      <div className={classes.ref} onClick={taskHandler}>
        <NavLink
          to={taskUrl}
          onClick={anchorHandler}
          style={{ color: props.color }}
        >
          <Typography
            variant="caption"
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
          >
            {props.task.ref}
            {props.isSegmentView && props.task.segmentCount > 1
              ? ` #${props.task.number}`
              : ""}
          </Typography>
        </NavLink>

        {!props.isSegmentView && props.task.segmentCount > 1 ? (
          <Typography variant="caption" sx={{ pl: "5px" }}>
            ({`${props.task.segmentCountInGroup} / ${props.task.segmentCount}`})
          </Typography>
        ) : (
          ""
        )}
      </div>

      <div className={classes.task} onClick={taskHandler}>
        <Typography variant="caption">
          {props.task.internalType === InternalTypeEnum.SPIKE && (
            <>
              <Tooltip title={InternalTypeEnum.SPIKE}>
                <img
                  src={theme.palette.spike.xsImg}
                  alt=""
                  style={{
                    position: "relative",
                    top: "2px",
                    width: "11px",
                    height: "11px",
                    backgroundColor: theme.palette.spike.backgroundColor,
                    padding: "1px",
                    borderRadius: "2px",
                  }}
                />
              </Tooltip>{" "}
            </>
          )}
          {props.task.name}
        </Typography>
      </div>
    </div>
  );
};

export default LeftContainer;
