import { TAvatarProps } from "./types/TAvatarProps";
import AvatarIcon from "../../../../user/containers/AvatarIcon/AvatarIcon";
import React, { useEffect, useState, useTransition } from "react";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import classes from "./Avatar.module.css";
import Menu from "../../../../../common/containers/Menu/Menu";
import IconImage from "../../../../../common/components/Icon/IconImage/IconImage";
import { IUser } from "../../../../../entities/IUser";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { Typography } from "@mui/material";
import { menuItem } from "../../../../../common/containers/Menu/utils/menuItem";
import { boardQueryActions } from "../../../slices/boardQuerySlice";
import { userActions } from "../../../../user/slices/userSlice";

const Avatar = ({ users }: TAvatarProps) => {
  const matchesMax1200Screen = useMedia(QueryEnum.MAX_WIDTH_1200);
  const dispatch = useAppDispatch();
  const userFilter = useAppSelector(
    (state) => state.boardQuery.filters.userIds
  );
  const [selectedIds, setSelectedIds] = useState<number[]>(userFilter ?? []);
  const [, startTransition] = useTransition();

  const userFilterHandler = (user: IUser) => {
    setSelectedIds((prevState) => {
      if (prevState.includes(user.id)) {
        return prevState.filter((id) => id !== user.id);
      } else {
        return [...prevState, user.id];
      }
    });
    startTransition(() => {
      dispatch(boardQueryActions.addUserFilter(user.id));
    });
  };

  useEffect(() => {
    startTransition(() => {
      dispatch(userActions.setSelectedIds(selectedIds));
      dispatch(userActions.setFilteredList(selectedIds));
    });
  }, [selectedIds.length]);

  return (
    <>
      {!matchesMax1200Screen && users.length <= 15 ? (
        users.map((user, index) => {
          return (
            <AvatarIcon
              className={classes.icon}
              clickHandler={() => userFilterHandler(user)}
              key={index}
              userName={user.name}
              tooltip={user.name}
              size={28}
              fontSize={11}
              isUnassigned={user.id === 0}
              isChecked={selectedIds.includes(user.id)}
            />
          );
        })
      ) : (
        <Menu
          stretchOnMobile={false}
          closeOnSelection={true}
          button={(clickHandler) => (
            <div onClick={clickHandler} className={classes["menu-icon"]}>
              <IconImage
                img="/icons/boardView/user-check-icon-light-64x64.png"
                imgDark="/icons/boardView/user-check-icon-dark-64x64.png"
                size={22}
              />
            </div>
          )}
          menuItems={users.map((user, index) =>
            menuItem.make(
              <div
                className={classes["menu-item"]}
                onClick={() => userFilterHandler(user)}
              >
                <div>
                  <AvatarIcon
                    className={classes.icon}
                    key={index}
                    userName={user.name}
                    tooltip={user.name}
                    size={25}
                    fontSize={10}
                    isUnassigned={user.id === 0}
                    isChecked={selectedIds.includes(user.id)}
                  />
                </div>
                <Typography sx={{ ml: "25px" }}>{user.name}</Typography>
              </div>
            )
          )}
        />
      )}
    </>
  );
};

export default Avatar;
