import { THttpClient } from "./types/THttpClient";
import { THttpClientError } from "./types/THttpClientError";
import { RequestMethodEnum } from "./enums/RequestMethodEnum";
import { jwt } from "../../utils/jwt";
import { query as queryHelper } from "../../utils/query";

const HANDLEABLE_ERROR_STATUSES = [422, 406, 401, 429, 404, 402, 403, 300];

export const httpClient: THttpClient = async (props) => {
  const { url, method, query, urlPath, body, headers, events, callbacks } =
    props;

  const baseUrl = props.baseUrl ?? process.env.BASE_URL;

  let requestUrl = url + (urlPath ?? "");

  if (query) {
    const { filters, ...restProps } = query;
    requestUrl = requestUrl + "?" + new URLSearchParams(restProps).toString();

    if (filters) {
      let queryParams = "";
      //@todo Improve this logic when needed to support all the filters currently we call
      // queryHelper.filters which gonna add filters[key][]=value to the query
      queryParams = queryHelper.filters(filters);
      requestUrl = `${requestUrl}${queryParams ? `&${queryParams}` : ""}`;
    }
  }

  // Dispatch event before request
  events?.beforeRequest?.({ query, body, urlPath });
  // Send request to the service
  const response = await fetch(baseUrl + requestUrl, {
    method: method,
    body:
      method !== RequestMethodEnum.GET && body ? JSON.stringify(body) : null,
    headers: {
      Authorization: jwt.bearer(),
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
  });

  // This is the case when some status came from the server, and we do not explicitly handle that
  if (!response.ok && !HANDLEABLE_ERROR_STATUSES.includes(response.status)) {
    // Dispatch event after request
    events?.afterRequest?.(null);

    console.error(
      `Unhandled response status for ${requestUrl}, status: ${response.status}`
    );
    return {
      data: null,
      error: {
        status: response.status,
        data: {
          message:
            "Technical error: something unexpected happened, please refresh the page and try again.",
        },
      } as THttpClientError,
    };
  }

  let data = null;
  let ac;
  // Stand for https success with No Content
  if (response.status !== 204) {
    // Parse the json from the response and validate that is has format expected by the system
    const json = await response.json();
    ac = json?.ac;
    const responseDataLayerNotForced =
      process.env.RESPONSE_DATA_LAYER_NOT_FORCED === "true";
    if (!responseDataLayerNotForced && !json.hasOwnProperty("data")) {
      console.error("Unexpected json body");
      return {
        ac: ac,
        data: null,
        error: {
          status: response.status,
          data: { message: "Technical error: bad response received." },
        } as THttpClientError,
      };
    }

    data = !responseDataLayerNotForced ? json.data : json;
    if (response.ok) {
      // Dispatch event before response transform
      events?.beforeTransform?.(data);
      // Perform response data transform if requested
      data = callbacks?.transformResponse
        ? callbacks.transformResponse(data)
        : data;
      // Dispatch event after response transform
      events?.afterTransform?.(data);
    }
  }

  // Dispatch event after request
  events?.afterRequest?.(response.ok ? data : null);

  return {
    ac: ac,
    data: response.ok ? data : null,
    error: !response.ok
      ? ({
          status: response.status,
          data: data,
        } as THttpClientError)
      : null,
  };
};
