import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import useCreateProject from "../../hooks/useCreateProject";
import { TCreateFormProps } from "./types/TCreateFormProps";
import { FormEvent } from "react";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { useAppSelector } from "../../../../common/hooks/redux";
import { projectUtil } from "../../../../common/utils/projectUtil";

const CreateForm = (props: TCreateFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const { list } = useAppSelector((state) => state.org.init);
  const projects = list.map((listItem) => listItem.project);
  const defaultStatusFlow = { id: 0, label: "Default" };
  const statusFlowList = [defaultStatusFlow, ...projectUtil.list(projects)];
  const {
    form,
    isLoading,
    submitHandler: formSubmitHandler,
  } = useCreateProject({
    formDefaultState: {
      name: "",
      abbr: "",
      statusFlowProject: defaultStatusFlow,
    },
  });

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    formSubmitHandler(props.onCreate);
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionPosition={props.actionPosition ?? "left"}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Create
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        showRequired
        disabled={isLoading}
      />

      <TextField
        form={form}
        name="abbr"
        label="Abbreviation"
        showRequired
        disabled={isLoading}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />

      {statusFlowList.length > 1 && (
        <Autocomplete
          form={form}
          name="statusFlowProject"
          label="Status Flow"
          options={statusFlowList}
          disableClearable
          textFieldProps={{
            helperText:
              "Select a status flow template from existing projects to accelerate your setup. If no template is chosen, a default option will be applied. If you select a template, you can customize the flow for your current project later.",
          }}
        />
      )}
    </Form>
  );
};

export default CreateForm;
