import { taskViewActions } from "../../../../../../modules/task/modules/view/slices/viewSlice";
import { Archive } from "@mui/icons-material";
import { Typography } from "@mui/material";
import QuickActionButton from "../QuickActionButton";
import { useAppDispatch } from "../../../../../hooks/redux";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";
import { TMoveToBacklogProps } from "./types/TMoveToBacklogProps";

export default function MoveToBacklog({
  taskId,
  taskInternalType,
}: TMoveToBacklogProps) {
  const dispatch = useAppDispatch();

  if (taskInternalType !== InternalTypeEnum.TASK) {
    return null;
  }

  return (
    <QuickActionButton
      onClick={() =>
        dispatch(
          taskViewActions.openMoveToBacklogConfirmation({
            taskId,
          })
        )
      }
    >
      <Archive fontSize="small" />
      <Typography fontSize="13px" fontWeight={500}>
        Move to Backlog
      </Typography>
    </QuickActionButton>
  );
}
