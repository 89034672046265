import { CompareArrows, Groups3 } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import ManageRoleMapModal from "./ManageRoleMapModal";

const ManageRoleMap = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        variant="text"
        startIcon={<CompareArrows />}
        onClick={() => dispatch(teamActions.openManageRoleMapModal())}
      >
        Role Label Mapping
      </Button>
      <ManageRoleMapModal />
    </>
  );
};

export default ManageRoleMap;
