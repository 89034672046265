import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAccessControlState } from "./types/TAccessControlState";
import { AbilityEnum } from "../enums/AbilityEnum";
import { RoleEnum } from "../../../../entities/columns/user/RoleEnum";

const initialState: TAccessControlState = {};

const slice = createSlice({
  name: "accessControl",
  initialState,
  reducers: {
    setAbilities: (state, action: PayloadAction<AbilityEnum[] | undefined>) => {
      // Set if not set / reset or if the length of the array is different
      // The abilities either will increase or decrease - if the same no need to cause a re-render
      if (
        !action.payload ||
        action.payload.length !== state.abilities?.length
      ) {
        state.abilities = action.payload;
      }
    },
    setAccessibleRoles: (
      state,
      action: PayloadAction<RoleEnum[] | undefined>
    ) => {
      // Set if not set / reset or if the length of the array is different
      // The abilities either will increase or decrease - if the same no need to cause a re-render
      if (
        !action.payload ||
        action.payload.length !== state.accessibleRoles?.length
      ) {
        state.accessibleRoles = action.payload;
      }
    },
  },
});

export const accessControlReducer = slice.reducer;

export const accessControlActions = slice.actions;
