import { useEffect, useRef } from "react";
import AiSentinelIcon from "./AiSentinelIcon";
import { Dialog, Fab, Popper, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { aiSentinelActions } from "../../slices/aiSentinelSlice";
import ChatBox from "../ChatBox/ChatBox";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

const AiSentinel = () => {
  const maxWidth768 = useMedia(QueryEnum.MAX_WIDTH_768);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.aiSentinel.isOpen);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const projectList = useAppSelector((state) => state.org.init.list);

  const theme = useTheme();

  const shouldAiRender = projectList.length > 0;

  const fabRightPosition = 20;
  const fabBottomPosition = 20;
  const fabHeight = anchorRef?.current?.offsetHeight || 56;
  const fabWidth = anchorRef?.current?.offsetWidth || 56;

  const popperMargin = `0 ${fabRightPosition + fabWidth / 2}px ${
    fabBottomPosition + fabHeight + 4
  }px 0 !important`;

  useEffect(() => {
    dispatch(aiSentinelActions.toggleAiRendered(shouldAiRender));
  }, [shouldAiRender]);

  if (!shouldAiRender) {
    return null;
  }

  return (
    <>
      <Fab
        ref={anchorRef}
        onClick={() => {
          dispatch(aiSentinelActions.toggleOpen());
        }}
        disableFocusRipple={true}
        sx={{
          position: "fixed",
          bottom: fabBottomPosition,
          right: fabRightPosition,
          zIndex: isOpen && maxWidth768 ? 0 : 1500,
          backgroundColor: theme.palette.aiSentinel.fabIconBackgroundColor,
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "rgba(39,141,118,0.18)",
          },
        }}
      >
        <AiSentinelIcon size={40} border="none" />
      </Fab>
      {isOpen &&
        (maxWidth768 ? (
          <Dialog open={isOpen} fullScreen scroll="paper">
            <ChatBox />
          </Dialog>
        ) : (
          <Popper
            open
            anchorEl={document.body}
            placement="left-end"
            sx={{
              position: "fixed !important",
              transform: "none !important",
              zIndex: 1500,
              width: "90%",
              maxWidth: { sm: 400 },
              margin: popperMargin,
              boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.2)",
              borderRadius: 5,
            }}
          >
            <ChatBox />
          </Popper>
        ))}
    </>
  );
};

export default AiSentinel;
