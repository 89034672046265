import { TAvatarGroupProps } from "./types/TAvatarGroupProps";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import classes from "./AvatarGroup.module.css";
import { useTheme } from "@mui/material";

const AvatarGroup = ({ users, count }: TAvatarGroupProps) => {
  const theme = useTheme();
  const numberToDisplay = count ?? 3;
  const havingMoreUsers = users.length > numberToDisplay;

  return (
    <>
      {users.slice(0, numberToDisplay).map((user, index) => (
        <AvatarIcon
          sx={{
            border: `2px solid ${theme.palette.taskView.mainBackground}`,
            mr: "-7px",
            // If there are more than 3 users - adding border to the last one
            // to indicate that there are more users
            ...(havingMoreUsers && index === numberToDisplay - 1
              ? { borderRight: theme.palette.avatar.border }
              : {}),
          }}
          avatarStyles={{ color: theme.palette.avatar.color, fontWeight: 500 }}
          className={classes.icon}
          key={user.id}
          userName={user.name}
          tooltip={user.name}
          position="right"
          size={22}
          fontSize={10}
          isBold={true}
          noPadding={true}
        />
      ))}
    </>
  );
};

export default AvatarGroup;
