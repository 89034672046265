import { Outlet, Route, Routes } from "react-router-dom";
import Board from "../../../../../modules/board/containers/Board/Board";
import UpdateInfoPage from "../../../../../modules/board/modules/settings/containers/UpdateInfo/UpdateInfoPage";
import Connect from "../../../../../modules/integrations/modules/slack/containers/Connect/Connect";
import OfftimePage from "../../../../../modules/offtime/containers/OfftimeManagement/OfftimePage";
import BoardIntegrationPage from "../../../../../modules/board/containers/Integration/IntegrationPage";
import PriorityView from "../../../../../modules/view/priorityView/containers/PriorityView";
import ProjectManagementPage from "../../../../../modules/project/containers/ProjectManagement/ProjectManagementPage";
import UpdateProjectPage from "../../../../../modules/project/containers/UpdateProject/UpdateProjectPage";
import IntegrationPage from "../../../../../modules/integrations/containers/IntegrationPage";
import TeamsPage from "../../../../../modules/team/containers/Teams/TeamsPage";
import { TeamManagementComponentEnum } from "../../../../../modules/team/enums/TeamManagementComponentEnum";
import TaskPage from "../../../../../modules/task/modules/view/containers/TaskPage";
import LandingPage from "../../../../modules/landingPage/containers/LandingPage/LandingPage";
import ProjectView from "../../../../../modules/view/projectView/containers/ProjectView";
import NotFound from "../../../../components/Page/NotFound/NotFound";
import CustomFieldsPage from "../../../../../modules/customField/containers/CustomFieldsPage";
import TaskFieldValuesPage from "../../../../../modules/board/modules/settings/containers/TaskFieldValues/TaskFieldValuesPage";
import TaskTypesPage from "../../../../../modules/taskType/containers/TaskTypesPage";
import AccountPage from "../../../../../modules/user/modules/account/containers/AccountPage";
import TaskPrioritiesPage from "../../../../../modules/taskPriority/containers/TaskPrioritiesPage";
import StatusFlowPage from "../../../../../modules/statusFlow/containers/StatusFlowPage";
import LinkListPage from "../../../../../modules/linkList/containers/LinkListPage";
import ResetPassword from "../../../../../modules/user/containers/Auth/ResetPassword/ResetPassword";
import ConfigNonWorkingDaysPage from "../../../../../modules/nonWorkday/containers/ConfigNonWorkingDaysPage";
import { useAccessControl } from "../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../modules/accessControl/enums/AbilityEnum";
import Loader from "../../../Loader/Loader";
import React, { ReactElement } from "react";
import SprintPage from "../../../../../modules/sprint/containers/SprintManagement/SprintPage";
import BacklogItemViewPage from "../../../../../modules/backlog/modules/view/containers/BacklogItemViewPage";
import InvoicePage from "../../../../../modules/stripe/containers/BillingSettings/InvoicePage";
import ObjectivesPage from "../../../../../modules/objective/containers/ObjectivesPage";
import ObjectivePage from "../../../../../modules/objective/containers/View/ObjectivePage";

const getOutletIfAllowed = (ability: AbilityEnum, component?: ReactElement) => {
  const { can, acLoaded, accessibleRoles } = useAccessControl();

  return !acLoaded ? (
    <Loader />
  ) : !can(ability) ? (
    <NotFound />
  ) : (
    component ?? <Outlet />
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path=":projectAbbr/board" element={<Outlet />}>
        <Route path=":boardRef/settings" element={<Outlet />}>
          <Route path="updateInfo" element={<UpdateInfoPage />} />
          <Route path="taskFieldValues" element={<TaskFieldValuesPage />} />
        </Route>
        <Route
          path=":boardRef/integration"
          element={<BoardIntegrationPage />}
        />
        <Route path=":boardRef/:boardView" element={<Board />} />
        <Route
          path=":boardRef/:boardView/:holisticViewVision"
          element={<Board />}
        />
        <Route path=":boardRef/*" element={<Board />} />
        <Route index element={<NotFound />} />
      </Route>
      <Route path="integration" element={<Outlet />}>
        <Route path="slack/connect/:key" element={<Connect />} />
        <Route
          path="list"
          element={getOutletIfAllowed(
            AbilityEnum.administrate,
            <IntegrationPage />
          )}
        />
      </Route>
      <Route path="/offtime" element={<OfftimePage />} />
      <Route path="/priorityView" element={<PriorityView />} />
      <Route path="/projectView" element={<ProjectView />} />
      <Route path="/project/:projectAbbr" element={<UpdateProjectPage />} />
      <Route path="/task/:taskRef/:segmentNumber?" element={<TaskPage />} />
      <Route
        path="/backlog/:backlogItemRef"
        element={<BacklogItemViewPage />}
      />
      <Route path="team" element={getOutletIfAllowed(AbilityEnum.administrate)}>
        <Route
          path=""
          element={
            <TeamsPage component={TeamManagementComponentEnum.MEMBERS} />
          }
        />
        <Route
          path="teams"
          element={<TeamsPage component={TeamManagementComponentEnum.TEAMS} />}
        />
      </Route>
      <Route
        path={"settings"}
        element={getOutletIfAllowed(AbilityEnum.administrate)}
      >
        <Route path="project" element={<ProjectManagementPage />} />
        <Route path="customFields" element={<CustomFieldsPage />} />
        <Route path="taskTypes" element={<TaskTypesPage />} />
        <Route path="taskPriorities" element={<TaskPrioritiesPage />} />
        <Route path="taskLinkList" element={<LinkListPage />} />
        <Route path="project/:projectAbbr" element={<Outlet />}>
          <Route path="statusFlow" element={<StatusFlowPage />} />
          <Route
            path="configNonWorkingDays"
            element={<ConfigNonWorkingDaysPage />}
          />
          <Route path="sprints" element={<SprintPage />} />
        </Route>
      </Route>
      <Route path={"user"} element={<Outlet />}>
        <Route path="account" element={<AccountPage />} />
        <Route path="resetPassword" element={<ResetPassword />} />
      </Route>
      <Route
        path={"billingSettings"}
        element={getOutletIfAllowed(AbilityEnum.financing)}
      >
        <Route path="invoice" element={<InvoicePage />} />
      </Route>
      <Route path="/objectives" element={<ObjectivesPage />} />
      <Route path="/objective/:objectiveRef" element={<ObjectivePage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
