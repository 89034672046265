import { useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import {
  ArrowDropDownRounded,
  ArrowOutward,
  Check,
  KeyboardArrowRightRounded,
  Link,
} from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import { TTaskViewHeaderProps } from "./types/TTaskViewHeaderProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { taskUtil } from "../../../../../../common/utils/taskUtil";
import { ITaskSegment } from "../../../../../../entities/ITaskSegment";
import { taskActions } from "../../../../slices/taskSlice";
import { taskViewActions } from "../../slices/viewSlice";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { link } from "../../../../../../common/utils/link";
import TaskTypeForm from "../TaskType/TaskTypeForm";
import TaskPriorityForm from "../TaskPriority/TaskPriorityForm";
import VerticalDivider from "../../../../../../common/components/Divider/VerticalDivider";
import TaskAggStatus from "../Status/TaskAggStatus";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";

export const TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT = 60;

const TaskViewHeader = ({
  task,
  isMobileView,
  isPageView,
}: TTaskViewHeaderProps) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const taskSegments =
    useAppSelector((state) => state.taskView.taskSegments) ?? [];
  const theme = useTheme();

  const taskUrl = taskUtil.urlByRef(
    task.ref,
    isSegmentView && task.hasSplits ? task.number : undefined
  );

  const isSpike = task.internalType === InternalTypeEnum.SPIKE;

  const copyLinkHandler = () => {
    setLinkCopied((prevState) => !prevState);
    navigator.clipboard.writeText(`${window.location.origin}${taskUrl}`);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  const taskSegmentClickHandler = (segment: ITaskSegment) => {
    dispatch(
      taskActions.mergeTaskProps({
        ...segment,
        id: task.taskId,
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
        taskId: task.taskId,
        segmentId: segment.id,
        isSegmentData: true,
      })
    );

    dispatch(taskViewActions.setViewType("segment"));
  };

  const switchToGroupViewHandler = () => {
    if (!task.hasSplits) {
      return;
    }
    dispatch(taskViewActions.setViewType("group"));
    dispatch(taskActions.setTask({ ...task, isSegmentData: false }));
    isPageView && navigate(`/task/${task.ref}`, { replace: true });
  };

  return (
    <>
      <Grid
        container
        bgcolor={theme.palette.taskView.secondaryBackground}
        borderBottom={`1px solid ${theme.palette.taskView.headerBorderBottomBackground}`}
        height={TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT}
        p={`16px ${isMobileView ? "40px 16px 16px" : "26px"}`}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item width="auto" alignItems="center">
          <Tooltip
            title={
              <>
                <div>
                  {!isSpike
                    ? `${InternalTypeEnum.TASK} `
                    : `${InternalTypeEnum.SPIKE} `}
                  {task.ref}
                </div>
                {task.hasSplits && <div>Click to switch to the group view</div>}
              </>
            }
          >
            <Grid
              item
              bgcolor={
                !isSpike
                  ? theme.palette.taskView.headerTitleBackground
                  : theme.palette.spike.backgroundColor
              }
              borderRadius="4px"
              fontSize="12px"
              fontWeight={500}
              p="7px 8px"
              onClick={switchToGroupViewHandler}
              sx={{ cursor: "pointer" }}
            >
              <span
                style={{
                  color: !isSpike
                    ? theme.palette.taskView.textMainColor
                    : theme.palette.spike.color,
                }}
              >
                {task.ref}
              </span>
            </Grid>
          </Tooltip>

          {isSegmentView && task.hasSplits && (
            <Grid item display="flex" alignItems="center">
              <KeyboardArrowRightRounded
                fontSize="small"
                sx={{
                  color: theme.palette.taskView.textMainColor,
                }}
              />
              <Box display="flex" gap="2px">
                <Box
                  bgcolor={theme.palette.taskView.headerTitleBackground}
                  borderRadius="4px 0 0 4px"
                  color={theme.palette.taskView.textMainColor}
                  fontSize="12px"
                  fontWeight={500}
                  p="7px 3px 7px 6px"
                >
                  #{task.number}
                </Box>

                <Menu
                  stretchOnMobile={false}
                  closeOnSelection={true}
                  sx={{
                    backgroundColor: theme.palette.taskView.secondaryBackground,
                    "&:before": {
                      content: "none",
                    },
                    hr: {
                      margin: "4px !important",
                    },
                  }}
                  button={(clickHandler) => (
                    <IconButton
                      sx={{
                        bgcolor: theme.palette.taskView.headerTitleBackground,
                        borderRadius: "0 4px 4px 0",
                        color: theme.palette.taskView.textMainColor,
                        padding: 0,
                      }}
                      onClick={clickHandler}
                    >
                      <ArrowDropDownRounded
                        fontSize="small"
                        sx={{
                          color: theme.palette.taskView.textMainColor,
                        }}
                      />
                    </IconButton>
                  )}
                  menuItems={taskSegments.map((taskSegment) =>
                    menuItem.make(
                      <Box fontSize="12px">
                        <NavLink
                          to={taskUtil.urlByRef(task.ref, taskSegment.number)}
                          onClick={link.anchorClickHandler}
                          style={{
                            color: theme.palette.taskView.textMainColor,
                          }}
                        >
                          #{taskSegment.number}
                        </NavLink>
                      </Box>,
                      () => {
                        taskSegmentClickHandler(taskSegment);
                      }
                    )
                  )}
                />
              </Box>
            </Grid>
          )}

          {!isPageView && (
            <Tooltip placement="right" title={"Open page view"}>
              <Grid item ml="4px" display="flex" alignItems="center">
                <NavLink to={taskUrl}>
                  <ArrowOutward
                    fontSize="small"
                    sx={{
                      color: theme.palette.taskView.textMainColor,
                    }}
                  />
                </NavLink>
              </Grid>
            </Tooltip>
          )}

          <Tooltip
            placement="right"
            title={!linkCopied ? "Copy link" : "Copied!"}
          >
            <Grid item ml="4px" display="flex" alignItems="center">
              {!linkCopied ? (
                <Link
                  fontSize="small"
                  sx={{
                    color: theme.palette.taskView.textMainColor,
                    cursor: "pointer",
                  }}
                  onClick={copyLinkHandler}
                />
              ) : (
                <Check color="success" fontSize="small" />
              )}
            </Grid>
          </Tooltip>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          gap={isMobileView ? "8px" : "12px"}
        >
          <TaskTypeForm noLabel={isMobileView} task={task} />

          <TaskPriorityForm noLabel={isMobileView} task={task} />

          {task.hasSplits && (
            <>
              <VerticalDivider
                sx={{
                  bgcolor: "rgba(0, 0, 0, 0.10)",
                  border: "none",
                  height: "28px",
                  m: 0,
                  width: "1px",
                }}
              />

              <TaskAggStatus task={task} showTitle={!isMobileView} />
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default TaskViewHeader;
