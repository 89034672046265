import { Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { TTaskViewDetails } from "./types/TTaskViewDetails";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import AssignedToForm from "../AssignedTo/AssignedToForm";
import EstimationForm from "../Estimation/EstimationForm";
import { StateHelper } from "../../../../../../common/components/Task/components/State/helpers/StateHelper";
import StateIcon from "../../../../../../common/components/Task/components/State/StateIcon";
import StartDateForm from "../StartDate/StartDateForm";
import DueDateForm from "../DueDate/DueDateForm";
import { date, FORMAT_TWO } from "../../../../../../common/utils/date";
import DetailsLabel from "./DetailsLabel";
import AvatarGroup from "../../../../../user/containers/AvatarGroup/AvatarGroup";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import React from "react";
import AddAssigneesButton from "../AssignedTo/AddAssigneesButton";

const TaskViewDetails = (props: TTaskViewDetails) => {
  const { task, resource } = props;
  const userList = resource.userList;

  const customs = useAppSelector((state) => state.customs);
  const sprintList = useAppSelector((state) => state.task.resource?.sprints);
  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const users = useAppSelector((state) => state.task.resource?.users) ?? [];
  const theme = useTheme();

  const assigneeUsers = users.filter((user) =>
    task.assignees.includes(user.id)
  );

  const createdAt = moment(task.createdAt);
  const updatedAt = moment(task.updatedAt);

  return (
    <Grid container spacing="20px" alignItems="center">
      {task.objective.length > 0 && (
        <>
          <Grid item xs={4}>
            <DetailsLabel>Objective</DetailsLabel>
          </Grid>
          <Grid item xs={8}>
            <Tooltip
              when={task.objective.length > 1}
              title={task.objective.map((initiativeName) => (
                <div>{initiativeName}</div>
              ))}
            >
              <Typography
                color={theme.palette.taskView.textSecondaryColor}
                fontSize="15px"
                fontWeight={500}
              >
                {task.objective[0]}
                {task.objective.length > 1 ? "..." : ""}
              </Typography>
            </Tooltip>
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <DetailsLabel>Board</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {task.boardName}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <DetailsLabel>{customs.sprintLabel}</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {sprintList?.find((sprint) => sprint.ref === props.task.sprintRef)
            ?.name ?? `Is not in any ${customs.sprintLabel}`}
        </Typography>
      </Grid>

      {isSegmentView && (
        <>
          <Grid item xs={4}>
            <DetailsLabel>Assigned By</DetailsLabel>
          </Grid>

          <Grid item xs={8}>
            <Typography
              color={theme.palette.taskView.textSecondaryColor}
              fontSize="15px"
              fontWeight={500}
            >
              {userList.find(
                (userListItem) => +userListItem.id === task.assignedBy
              )?.label ?? "AI Sentinel"}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <DetailsLabel>Assigned To</DetailsLabel>
      </Grid>

      <Grid item xs={8}>
        {isSegmentView ? (
          <AssignedToForm userList={userList} task={task} />
        ) : (
          <>
            <AvatarGroup users={assigneeUsers} count={9} />
            <AddAssigneesButton taskId={task.id} />
          </>
        )}
      </Grid>

      <EstimationForm task={task}></EstimationForm>

      {isSegmentView &&
        StateHelper.willDisplayState(task.statusType, task.state) && (
          <>
            <Grid item xs={4}>
              <DetailsLabel>State</DetailsLabel>
            </Grid>

            <Grid container item xs={8} alignItems="center" gap="8px">
              <StateIcon
                placement="left"
                state={task.state}
                status={task.status}
                statusType={task.statusType}
                style={{ display: "flex" }}
              />
              <Typography
                color={theme.palette.taskView.textSecondaryColor}
                fontSize="15px"
                fontWeight={500}
                sx={{
                  "::first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {StateHelper.getLabel(
                  task.statusType,
                  task.status,
                  task.state
                ).toLowerCase()}
              </Typography>
            </Grid>
          </>
        )}

      <StartDateForm task={task} />

      <DueDateForm task={task} />

      {!isSegmentView && (
        <Grid item xs={12}>
          <Typography
            color={theme.palette.taskView.labelColor}
            fontSize="12px"
            fontWeight={700}
          >
            Choose a segment to see details
          </Typography>
        </Grid>
      )}

      <Grid container item mt="16px" gap="4px">
        <Grid item xs={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Created {date.format(createdAt, FORMAT_TWO)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Updated {date.format(updatedAt, FORMAT_TWO)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskViewDetails;
