import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { Grid } from "@mui/material";
import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useUpdateProjectService } from "../../services/useUpdateProjectService";
import { useNavigate } from "react-router-dom";
import { useFetchBoardListByProjectRefService } from "../../../board/services/useFetchBoardListByProjectRefService";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { orgActions } from "../../../org/slices/orgSlice";

const UpdateForm = (props: TUpdateFormProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const board = useAppSelector((state) =>
    state.org.init.list
      .find(
        (data) => data.project.defaultBoardRef === props.data?.defaultBoardRef
      )
      ?.boards.find((board) => board.ref === props.data?.defaultBoardRef)
  );

  const form = useForm<TUpdateFormAttributes>(
    props.data && {
      abbr: props.data?.abbr,
      name: props.data?.name,
      defaultBoardRef: {
        id: board?.ref!,
        label: board?.name!,
      },
    }
  );

  const { isLoading, dispatch: dispatchUpdateProject } =
    useUpdateProjectService(props?.data?.ref);
  const fetchBoardListByProjectRefService =
    useFetchBoardListByProjectRefService({
      ref: props?.data?.ref,
    });
  const isDisabled = isLoading || props.isDataLoading;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdateProject({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        name: data.name,
        abbr: data.abbr.toUpperCase(),
        defaultBoardRef: data.defaultBoardRef?.id,
      })),
    })
      .then(() => {
        dispatch(
          orgActions.updateProjectData({
            projectRef: board?.projectRef!,
            name: form.data?.name!,
            abbr: form.data?.abbr!.toUpperCase()!,
            defaultBoardRef: form.data?.defaultBoardRef?.id!,
          })
        );
        form.notDirty();
        dispatch(
          systemNotificationActions.open({
            message: "Your project has been updated",
            variant: "success",
          })
        );
        navigate(`/project/${form?.data?.abbr.toUpperCase()}`, {
          replace: true,
        });
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            disabled={isDisabled || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            name="name"
            label="Name"
            showRequired
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            name="abbr"
            label="Abbreviation"
            showRequired
            disabled={isDisabled}
            helperText={`Tasks of this project will be prefixed with this abbreviation. For example: [ABC-123]. Changing this will not affect existing tasks.`}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            form={form}
            name="defaultBoardRef"
            label="Default board"
            service={fetchBoardListByProjectRefService}
            showRequired
            disabled={isDisabled}
            options={[{ id: board?.ref!, label: board?.name! }]}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default UpdateForm;
