import {
  Divider,
  ListSubheader,
  Menu as MuiMenu,
  MenuItem,
} from "@mui/material";
import useMedia from "../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../hooks/useMedia/enums/QueryEnum";
import { TMenuProps } from "./types/TMenuProps";
import { useState } from "react";

const Menu = (props: TMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const open = Boolean(anchorEl);

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const menuItems = props.menuItems
    .filter(
      (item) => !!item.component && (item.when === undefined || item.when)
    )
    .map((value, index, array) => ({
      ...value,
      hasDivider: array.length > index + 1,
    }));

  return menuItems.length > 0 ? (
    <>
      {props.button(clickHandler)}
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={closeHandler}
        onClick={(e) =>
          props.closeOnSelection !== false ? closeHandler(e) : undefined
        }
        PaperProps={{
          elevation: 0,
          sx: {
            ...((props.stretchOnMobile ?? true) &&
              matchesMax576Screen && { width: "95%" }),
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            ...props.sx,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {props.listSubheader && (
          <ListSubheader
            inset={props.indentSubheader ?? true}
            sx={{ lineHeight: 2 }}
          >
            {props.listSubheader}
          </ListSubheader>
        )}
        {menuItems.map((item, index) => [
          <MenuItem
            key={index}
            onClick={item.handler}
            sx={matchesMax576Screen ? { minHeight: "20px" } : {}}
          >
            {item.component}
          </MenuItem>,
          item?.hasDivider && <Divider />,
        ])}
      </MuiMenu>
    </>
  ) : null;
};

export default Menu;
