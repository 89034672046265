import { TBoardSettingsSectionProps } from "./types/TBoardSettingsSectionProps";
import List from "../../common/List/List";
import { DriveFileRenameOutline, Settings } from "@mui/icons-material";
import ArchiveBoard from "../../../../../../../modules/board/containers/Archive/ArchiveBoard";

const BoardSettingsSection = (props: TBoardSettingsSectionProps) => {
  return (
    <List
      title="Board Settings"
      items={[
        {
          id: "BoardUpdateInfoListItem",
          name: "Update Info",
          icon: <Settings />,
          path: `/${props.projectAbbr}/board/${props.boardRef}/settings/updateInfo`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
        {
          id: "BoardTaskDefaultValuesListItem",
          name: "Task Field Values",
          icon: <DriveFileRenameOutline />,
          path: `/${props.projectAbbr}/board/${props.boardRef}/settings/taskFieldValues`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
      ]}
    >
      <ArchiveBoard />
    </List>
  );
};

export default BoardSettingsSection;
