import { Grid, List, ListItem, ListItemText, Switch } from "@mui/material";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import React, { useEffect } from "react";
import moment from "moment/moment";
import { useToggleOfftimeStatusService } from "../../services/useToggleOfftimeStatusService";
import { offtimeActions } from "../../slices/offtimeSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { IToggleOfftimeStatusRequest } from "../../interfaces/IToggleOfftimeStatusRequest";
import { useFetchOfftimeService } from "../../services/useFetchOfftimeService";
import Loader from "../../../../common/containers/Loader/Loader";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TGeneralProps } from "./types/TGeneralProps";

const OfftimeList = (props: TGeneralProps) => {
  const { isLoading } = useFetchOfftimeService({ userId: props.userId });
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.offtime.list);
  const {
    isLoading: isLoadingToggleChange,
    dispatch: dispatchToggleOfftimeStatus,
  } = useToggleOfftimeStatusService();

  useEffect(() => {
    return () => {
      dispatch(offtimeActions.setList([]));
    };
  }, []);

  const toggleStatusHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const payload: IToggleOfftimeStatusRequest = {
      id,
      isActive: event.target.checked,
    };
    dispatchToggleOfftimeStatus({
      body: payload,
      urlPath: !props.userId ? "" : `/for/${props.userId}`,
    })
      .then(() => {
        dispatch(offtimeActions.toggleStatus(payload));
        dispatch(
          systemNotificationActions.open({
            message: payload.isActive
              ? "Offtime activated"
              : "Offtime deactivated",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message:
              error.status === 406
                ? error.data.message
                : "Activation unsuccessful. Please check for possible overlap in your designated off-times.",
            variant: "warning",
          })
        );
      });
  };

  const getDateFormat = (startDate: string, endDate: string) => {
    const momentFormat = "MMM D, YYYY";
    const startDateFormatted = moment(startDate).format(momentFormat);
    const endDateFormatted = moment(endDate).format(momentFormat);
    return `From ${startDateFormatted} till ${endDateFormatted}`;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container>
        <Grid item xs={12} md={6}>
          <List>
            {data?.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Switch
                      checked={item.isActive}
                      disabled={isLoadingToggleChange}
                      color="success"
                      onChange={(e) => toggleStatusHandler(e, item.id)}
                    />
                  }
                >
                  <ListItemText
                    primary={item.description}
                    secondary={getDateFormat(item.startDate, item.endDate)}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default OfftimeList;
