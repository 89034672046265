import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { Delete, FileDownload } from "@mui/icons-material";
import moment from "moment/moment";
import { TAttachmentRowProps } from "./types/TAttachmentRowProps";
import { IDownloadSignedUrlResource } from "../../interfaces/IDownloadSignedUrlResource";
import { useAppDispatch } from "../../../../common/hooks/redux";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { attachmentActions } from "../../slices/attachmentSlice";
import { useAttachmentDownloadService } from "../../services/useAttachmentDownloadService";
import { useAttachmentDeleteService } from "../../services/useAttachmentDeleteService";
import { number } from "../../../../common/utils/number";
import { FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE } from "../../../../common/utils/date";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import { ConfirmationButton } from "../../../../common/modules/materialUI";

const AttachmentRow = (props: TAttachmentRowProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  const hasPreview = props.attachment.previewType !== null;

  const onClickHandler = () => {
    if (!hasPreview) {
      dispatch(
        systemNotificationActions.open({
          variant: "info",
          message:
            "Preview is not available for this file type. Download instead.",
        })
      );

      return;
    }

    dispatch(
      attachmentActions.openPreviewModal({
        attachmentId: props.attachment.id as number,
        previewType: props.attachment.previewType!,
      })
    );
  };

  const {
    isLoading: isDownloading,
    dispatch: dispatchTaskAttachmentDownloadService,
  } = useAttachmentDownloadService({
    attachmentId: props.attachment.id as number,
  });

  const {
    isLoading: isDeleting,
    dispatch: dispatchTaskAttachmentDeleteService,
  } = useAttachmentDeleteService({
    attachmentId: props.attachment.id as number,
  });

  const deleteAttachmentHandler = () => {
    if (isDeleting) {
      return;
    }

    dispatchTaskAttachmentDeleteService()
      .then(() => {
        dispatch(
          attachmentActions.removeFromList({
            id: props.attachment.id as number,
          })
        );
      })
      .catch(() => {});
  };

  const downloadAttachmentHandler = () => {
    dispatchTaskAttachmentDownloadService().then(
      (data: IDownloadSignedUrlResource) => {
        location.href = data.downloadUrl;
      }
    );
  };

  return (
    <Grid
      container
      item
      px={props.isMobileView ? 0 : "8px"}
      py="2px"
      alignItems="center"
      justifyContent="space-between"
      gap={matchesMax576Screen ? 0 : "32px"}
    >
      <Grid item minWidth={0} flex={1} onClick={onClickHandler}>
        <Typography
          className="text-truncate"
          color={theme.palette.taskView.textMainColor}
          fontSize="13px"
          fontWeight={500}
          sx={{ cursor: "pointer" }}
        >
          {props.attachment.name}
        </Typography>
      </Grid>

      <Grid item display="flex" alignItems="center">
        {!matchesMax576Screen && (
          <>
            <Typography
              color={theme.palette.taskView.textMainColor}
              fontSize="13px"
              mr="20px"
            >
              {number.formatBytes(props.attachment.size)}
            </Typography>

            <Typography
              color={theme.palette.taskView.textMainColor}
              fontSize="13px"
              mr="8px"
            >
              {moment(props.attachment.createdAt).format(
                FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE
              )}
            </Typography>
          </>
        )}

        <Grid display="flex" alignItems="center" gap="4px">
          {props.attachment?.isUploading ? (
            <Loading variant="indeterminate" color="primary" size={18} />
          ) : (
            <>
              {!isDownloading ? (
                <FileDownload
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={downloadAttachmentHandler}
                />
              ) : (
                <Loading />
              )}
              <ConfirmationButton
                title="Delete Attachment"
                content="Are you sure you want to delete attachment?"
                button={
                  !isDeleting ? (
                    <Delete fontSize="small" style={{ cursor: "pointer" }} />
                  ) : (
                    <Loading />
                  )
                }
                successHandler={deleteAttachmentHandler}
              />
            </>
          )}
        </Grid>
      </Grid>

      {matchesMax576Screen && (
        <>
          <Grid item xs={12}>
            <Typography
              color={theme.palette.taskView.labelColor}
              fontSize="11px"
            >
              {moment(props.attachment.createdAt).format(
                FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE
              )}
            </Typography>

            <Typography
              color={theme.palette.taskView.labelColor}
              fontSize="11px"
              mr="20px"
            >
              {number.formatBytes(props.attachment.size)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider
              sx={{
                backgroundColor:
                  theme.palette.taskView.attachmentDividerBackground,
                border: "none",
                height: "1px",
                mt: "8px",
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AttachmentRow;
