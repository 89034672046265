import * as React from "react";
import { IconButton, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { TAvatarIconProps } from "./types/TAvatarIconProps";
import { jwt } from "../../../../common/utils/jwt";
import { string } from "../../../../common/utils/string";
import { Tooltip } from "../../../../common/modules/materialUI";

const AvatarIcon = (props: TAvatarIconProps) => {
  const userJwt = jwt.parse()!;
  const theme = useTheme();
  const sxProps = {
    ml: props.position === "right" ? "auto" : "",
    p: props.noPadding ? 0 : "",
    ...props.sx,
  };

  return (
    <Tooltip when={!!props.tooltip} title={props.tooltip}>
      <IconButton
        className={props.className}
        onClick={props.clickHandler}
        size="small"
        sx={sxProps}
        disableRipple={!!props.disableRipple}
      >
        <Avatar
          sx={{
            bgcolor: "primary.main",
            width: props.size ?? 30,
            height: props.size ?? 30,
            fontSize: props.fontSize ?? (props.size ? props.size / 2 : 14),
            fontWeight: props.isBold ? "bold" : "normal",
            ...props.avatarStyles,
          }}
        >
          {props.isUnassigned && (
            <img
              src={`${process.env.CDN_URL}/icons/${
                theme.palette.mode === "dark"
                  ? "unassigned.png"
                  : "unassigned-light.png"
              }`}
              alt="Example"
              width="11"
              height="11"
            />
          )}
          {!props.isUnassigned &&
            string.acronym(props.userName ?? userJwt.name)}
          {props.isChecked && (
            <img
              src={`${process.env.CDN_URL}/icons/check.png`}
              alt="Example"
              width="11"
              height="11"
              style={{
                color: "primary.main",
                position: "absolute",
                fontSize: "13px",
                bottom: "0px",
                left: "50%",
                transform: "translate(-50%, 0)",
              }}
            />
          )}
        </Avatar>
      </IconButton>
    </Tooltip>
  );
};

export default AvatarIcon;
