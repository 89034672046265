import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useEffect } from "react";
import { guesstimationViewActions } from "../../slices/guesstimationViewSlice";
import ViewSkeleton from "../../../holisticView/containers/View/ViewSkeleton";
import EmptyView from "../../../../../board/containers/EmptyView/EmptyView";
import GuesstimationViewData from "./GuesstimationViewData";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import { useGuesstimationViewQueryParams } from "../../hooks/useGuesstimationViewQueryParams";
import { useFetchGuesstimationViewDataService } from "../../services/useFetchGuesstimationViewDataService";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemCreatedEvent } from "../../../../../guesstimation/modules/create/containers/CreateItem/types/TGuesstimationItemCreatedEvent";
import { TGuesstimationItemUpdatedEvent } from "../../../../../guesstimation/modules/view/containers/types/TGuesstimationItemUpdatedEvent";
import { TGuesstimationItemDeletedEvent } from "../../../../../guesstimation/modules/view/containers/types/TGuesstimationItemDeletedEvent";
import { IGuesstimationViewDataResource } from "../../../../interfaces/IGuesstimationViewDataResource";

type TProps = {
  data: IGuesstimationViewDataResource;
  showSkeleton: boolean;
} & TBoardViewProps;

const GuesstimationViewContent = (props: TProps) => {
  const dispatch = useAppDispatch();
  const { data, showSkeleton } = props;
  const queryParams = useGuesstimationViewQueryParams({
    projectAbbr: props.projectAbbr,
  });
  const queryParamsString = JSON.stringify(queryParams);
  const { dispatch: dispatchFetchGuesstimationViewDataService } =
    useFetchGuesstimationViewDataService({
      boardRef: props.boardRef,
      query: queryParams,
    });
  const hasData = !!data?.guesstimationItems.length;

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_CREATED,
    (_: TGuesstimationItemCreatedEvent) => {
      dispatchFetchGuesstimationViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString, props.boardRef]
  );

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_UPDATED,
    (_: TGuesstimationItemUpdatedEvent) => {
      dispatchFetchGuesstimationViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString, props.boardRef]
  );

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_DELETED,
    (_: TGuesstimationItemDeletedEvent) => {
      dispatchFetchGuesstimationViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString, props.boardRef]
  );

  useEffect(() => {
    dispatchFetchGuesstimationViewDataService({
      query: queryParams,
    });
  }, [props.boardRef, queryParamsString]);

  useEffect(() => {
    dispatch(
      guesstimationViewActions.setWorkingHours(props.board.workingHours)
    );
  }, [props.boardRef]);

  return (
    <>
      {/*@TODO build own view skeleton maybe*/}
      {showSkeleton && <ViewSkeleton />}

      {/*@TODO build own maybe empty view*/}
      {!showSkeleton && !hasData && <EmptyView {...props} />}

      {!showSkeleton && hasData && (
        <GuesstimationViewData {...props} data={data} />
      )}
    </>
  );
};

export default GuesstimationViewContent;
