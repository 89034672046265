import Title from "../../../../common/components/Title/Title";
import AddIntegration from "./AddIntegration";
import LinkedIntegrationList from "./LinkedIntegrationList";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";

const IntegrationPage = () => {
  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, []);

  return (
    <>
      <Title>Board Integrations</Title>
      <AddIntegration />
      <LinkedIntegrationList />
    </>
  );
};

export default IntegrationPage;
