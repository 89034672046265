import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOrgState } from "./types/TOrgState";
import { IBoard } from "../../../entities/IBoard";
import { BoardTypeEnum } from "../../../entities/columns/board/BoardTypeEnum";
import { IInitDataResource } from "../interfaces/IInitDataResource";
import { ITaskType } from "../../../entities/ITaskType";
import { ITaskPriority } from "../../../entities/ITaskPriority";
import {
  findProjectByAbbr,
  findProjectByRef,
} from "../hooks/useOrgProjectData";
import { IStatus } from "../../../entities/IStatus";

const initialState: TOrgState = {
  init: {
    isLoading: true,
    list: [],
    taskTypes: [],
    taskPriorities: [],
    linkList: [],
    statuses: [],
  },
};

const slice = createSlice({
  name: "org",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.init.isLoading = action.payload;
    },
    init: (state, action: PayloadAction<IInitDataResource>) => {
      state.init.org = action.payload.org;
      state.init.list = action.payload.projectWithBoards;
      state.init.taskTypes = action.payload.taskTypes;
      state.init.taskPriorities = action.payload.taskPriorities;
      state.init.hasMembers = action.payload.hasMembers;
      state.init.hasTasks = action.payload.hasTasks;
      state.init.linkList = action.payload.linkList;
      state.init.statuses = action.payload.statuses;
      state.init.aiSentinel = action.payload.aiSentinel;
    },

    removeProject: (state, action: PayloadAction<{ ref: string }>) => {
      state.init.list = state.init.list.filter(
        (item) => item.project.ref !== action.payload.ref
      );
    },

    addTaskType: (state, action: PayloadAction<ITaskType>) => {
      state.init.taskTypes = [action.payload, ...state.init.taskTypes];
    },
    updateTaskType: (state, action: PayloadAction<ITaskType>) => {
      state.init.taskTypes = state.init.taskTypes.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteTaskType: (state, action: PayloadAction<{ id: number }>) => {
      state.init.taskTypes = state.init.taskTypes.map((item) =>
        item.id === action.payload.id ? { ...item, isActive: false } : item
      );
    },

    addTaskPriority: (state, action: PayloadAction<ITaskPriority>) => {
      state.init.taskPriorities = [
        action.payload,
        ...state.init.taskPriorities,
      ];
    },
    updateTaskPriority: (state, action: PayloadAction<ITaskPriority>) => {
      state.init.taskPriorities = state.init.taskPriorities.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteTaskPriority: (state, action: PayloadAction<{ id: number }>) => {
      state.init.taskPriorities = state.init.taskPriorities.map((item) =>
        item.id === action.payload.id ? { ...item, isActive: false } : item
      );
    },

    addBoard: (state, action: PayloadAction<IBoard>) => {
      state.init.list
        .find((data) => data.project.ref === action.payload.projectRef)
        ?.boards.push(action.payload);
    },
    updateProjectData: (
      state,
      action: PayloadAction<{
        projectRef: string;
        name: string;
        abbr: string;
        defaultBoardRef: string;
      }>
    ) => {
      const project = findProjectByRef(
        state.init.list,
        action.payload.projectRef
      )?.project;
      if (project) {
        project.name = action.payload.name;
        project.abbr = action.payload.abbr;
        project.defaultBoardRef = action.payload.defaultBoardRef;
      }
    },
    updateBoardData: (
      state,
      action: PayloadAction<{
        projectAbbr: string;
        boardRef: string;
        name?: string;
        workingHours?: number;
        type?: BoardTypeEnum;
      }>
    ) => {
      const board = state.init.list
        .find((data) => data.project.abbr === action.payload.projectAbbr)!
        .boards.find((board) => board.ref === action.payload.boardRef)!;
      action.payload.name && (board.name = action.payload.name);
      action.payload.workingHours &&
        (board.workingHours = action.payload.workingHours);
      action.payload.type && (board.type = action.payload.type);
    },
    setHasMembers: (state, action: PayloadAction<boolean>) => {
      state.init.hasMembers = action.payload;
    },
    setHasTasks: (state, action: PayloadAction<boolean>) => {
      state.init.hasTasks = action.payload;
    },
    removeBoard: (
      state,
      action: PayloadAction<{ projectAbbr: string; ref: string }>
    ) => {
      const project = findProjectByAbbr(
        state.init.list,
        action.payload.projectAbbr
      );
      if (project) {
        project.boards = project.boards.filter(
          (board) => board.ref !== action.payload.ref
        );
        state.init.list = state.init.list.map((item) =>
          item.project.abbr === action.payload.projectAbbr ? project : item
        );
      }
    },
    updateProjectStatuses: (
      state,
      action: PayloadAction<{ projectId: number; statuses: IStatus[] }>
    ) => {
      const filteredStatuses = state.init.statuses.filter(
        (status) => status.projectId !== action.payload.projectId
      );

      state.init.statuses = [...action.payload.statuses, ...filteredStatuses];
    },
    deleteStatus: (state, action: PayloadAction<{ statusId: number }>) => {
      state.init.statuses = state.init.statuses.filter(
        (status) => status.id !== action.payload.statusId
      );
    },
  },
});

export const orgReducer = slice.reducer;

export const orgActions = slice.actions;
