import classes from "./View.module.scss";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { useEffect, useRef } from "react";
import TimeLine from "../../../../../../common/components/TimeLine/TimeLine";
import UserLane from "../UserLane/UserLane";
import moment from "moment";
import { Card, CardContent, debounce } from "@mui/material";
import { TViewProps } from "./types/TViewProps";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TaskCustomDragLayer from "../Task/TaskCustomDragLayer";
import { HolisticViewConfig } from "../../config/Config";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { ITask } from "../../../../../../entities/ITask";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import { useUpdateUserLanes } from "../../hooks/useUpdateUserLanes";
import ViewContainer from "../../../../containers/ViewContainer/ViewContainer";
import VisionPicker from "../Filter/VisionPicker/VisionPicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { windowHelper } from "../../../../../../common/utils/windowHelper";
import {
  captureLastContainerRef,
  captureLastScrollPosition,
  captureTimelineMinDate,
  scrollTo,
  useVirtualizationHelper,
} from "../../hooks/useVirtualizationHelper";
import { date } from "../../../../../../common/utils/date";
import { holisticViewActions } from "../../slices/holisticViewSlice";
import { LoadDirectionEnum } from "../../../../enums/LoadDirectionEnum";
import { TBoardQueryState } from "../../../../slices/types/TBoardQueryState";

const View = ({ data, ...props }: TViewProps & TBoardViewProps) => {
  let users = useAppSelector((state) => state.user.userFilteredList);
  const selectedUserIds = useAppSelector((state) => state.user.selectedIds);
  const { extraFilters } = useAppSelector((state) => state.boardQuery);
  const { taskTypeIds, taskPriorityIds, sprintRefs, customFields } =
    extraFilters;

  const timelineMinDate = moment(
    useAppSelector((state) => state.holisticView.timelineDisplayFromDate)
  );
  const timelineMaxDate = moment(
    useAppSelector((state) => state.holisticView.timelineDisplayToDate)
  );
  const {
    canLoad,
    getLastAllowedDate,
    getDayByCoords,
    getCoordsByDay,
    scrollToToday,
    jumpOverDetected,
  } = useVirtualizationHelper();
  const dispatch = useAppDispatch();
  const { dispatch: dispatchUpdateUserLane } = useUpdateUserLanes({
    projectAbbr: props.projectAbbr,
    boardRef: props.boardRef,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const { sprints, userLaneData } = data;

  const minDate = moment(data.minDate!).startOf("day");
  const maxDate = moment(data.maxDate!);
  const canvasWidth =
    pointConverter.pointToXSizeInPixels() *
    date.intervalToDays(timelineMinDate, timelineMaxDate, true, false);

  const filtersData = JSON.stringify({
    taskTypeIds,
    taskPriorityIds,
    sprintRefs,
    customFields,
  });

  useSubscribeEvent(
    EventEnum.ON_TASK_CREATED,
    (task: ITask) => {
      dispatchUpdateUserLane();
    },
    [filtersData]
  );

  useSubscribeEvent(
    EventEnum.ON_TASK_MAIN_DATA_UPDATED,
    () => {
      dispatchUpdateUserLane();
    },
    [filtersData]
  );

  useSubscribeEvent(
    EventEnum.ON_BOARD_FILTERS_CHANGED,
    (payload: TBoardQueryState["extraFilters"]) => {
      dispatchUpdateUserLane(payload);
    },
    [filtersData]
  );

  useSubscribeEvent(
    EventEnum.ON_SPRINT_CREATED,
    () => {
      dispatchUpdateUserLane();
    },
    [filtersData]
  );

  useEffect(() => {
    // Set horizontal scroller to be few days back from today's date for better and easier visibility
    if (containerRef && containerRef.current) {
      // Cache the last container reference, so it can be used for later dynamic scroll operations
      captureLastContainerRef(containerRef.current);

      // Scroll to today's date as a starting point
      scrollToToday(timelineMinDate);
    }
  }, [containerRef]);

  useEffect(() => {
    const weeksToLoad = windowHelper.widthToWeeks();

    const dataHandler = (event: Event) => {
      const target = event.target as HTMLElement;
      const scrollLeft = target.scrollLeft;
      const clientWidth = target.clientWidth;

      const minDateStr = minDate.toISOString();
      const maxDateStr = maxDate.toISOString();

      // This is when user scrolls to the very left or right and jumps over the next
      // checkpoint on the right ot left side, so we load info in current date and reset
      // all the current scroll checkpoints
      jumpOverDetected(scrollLeft, minDateStr, maxDateStr, (date) => {
        canLoad(moment(date)) && dispatchUpdateUserLane();
      });

      // Save the last scroll position
      captureLastScrollPosition(scrollLeft);

      if (getDayByCoords(scrollLeft)?.day === minDateStr) {
        canLoad(minDate) &&
          dispatch(
            holisticViewActions.setVirtualizationParams({
              data: {
                weeksToLoad: weeksToLoad,
                // This function is having date only after the canLoad() approval
                loadFromDate: getLastAllowedDate()!.toISOString(),
                loadDirection: LoadDirectionEnum.PREV,
              },
            })
          );
      } else if (getDayByCoords(scrollLeft + clientWidth)?.day === maxDateStr) {
        canLoad(maxDate) &&
          dispatch(
            holisticViewActions.setVirtualizationParams({
              data: {
                weeksToLoad: weeksToLoad,
                // This function is having date only after the canLoad() approval
                loadFromDate: getLastAllowedDate()!.toISOString(),
                loadDirection: LoadDirectionEnum.NEXT,
              },
            })
          );
      }
    };

    // Add event listener on scroll to detect if user has scrolled to specific min/max dates on
    // the timeline to load more data
    containerRef?.current?.addEventListener("scroll", dataHandler);

    return () => {
      containerRef?.current?.removeEventListener("scroll", dataHandler);
    };
  }, [containerRef, minDate, maxDate]);

  useEffect(() => {
    timelineMinDate && captureTimelineMinDate(timelineMinDate);

    const timelineDisplayHandler = (event: Event) => {
      const target = event.target as HTMLElement;
      const scrollLeft = target.scrollLeft;
      const scrollWidth = target.scrollWidth;
      const clientWidth = target.clientWidth;
      const leftThreshold = 0;
      // Noticed that on the right side the scroll right gave 0.5
      const rightThreshold = 1;

      if (scrollLeft <= leftThreshold) {
        // Load more data to the left
        if (canLoad(timelineMinDate, "timeline")) {
          dispatch(holisticViewActions.expandTimelineDisplayFromDate());
          // Scroll back to previous position before the expansion for better user experience
          setTimeout(
            () => scrollTo(getCoordsByDay(timelineMinDate.toISOString())),
            100
          );
        }
      } else if (scrollWidth - (scrollLeft + clientWidth) <= rightThreshold) {
        // Load more data to the right
        canLoad(timelineMaxDate, "timeline") &&
          dispatch(holisticViewActions.expandTimelineDisplayToDate());
      }
    };

    // Add event listener on scroll to detect if user has scrolled to the end of the timeline
    // or to the beginning of the timeline to load more timeline section
    containerRef?.current?.addEventListener(
      "scroll",
      debounce(timelineDisplayHandler, 200)
    );

    return () => {
      containerRef?.current?.removeEventListener(
        "scroll",
        timelineDisplayHandler
      );
    };
  }, [containerRef, timelineMinDate, timelineMaxDate]);

  return (
    <ViewContainer boardRef={props.boardRef}>
      <Card>
        <CardContent sx={{ pt: 0.4, pr: 0.3 }}>
          <VisionPicker hasSprint={data.sprints.length > 0} />
        </CardContent>
        <CardContent
          ref={containerRef}
          className={`${classes.content}`}
          style={{ padding: `0.5rem 2rem 0.5rem 0` }}
        >
          <div className={classes["timeline-container"]}>
            <div className={classes["timeline-left"]} />
            <TimeLine
              positionShift={HolisticViewConfig.userSectionWidth}
              startDate={timelineMinDate}
              endDate={timelineMaxDate}
              sprints={sprints}
              nonWorkdays={data.nonWorkdays}
            />
          </div>
          <DndProvider backend={HTML5Backend}>
            <TaskCustomDragLayer />
            {users.map((userEntity, index) => (
              <UserLane
                key={`u-l-${userEntity.id ?? 0}`}
                minDate={timelineMinDate}
                maxDate={timelineMaxDate}
                width={canvasWidth}
                user={userEntity}
                isSelected={selectedUserIds.includes(userEntity.id ?? 0)}
              />
            ))}
          </DndProvider>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

export default View;
