import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppSelector } from "../../../../common/hooks/redux";
import Loader from "../../../../common/containers/Loader/Loader";
import { Navigate, useNavigate } from "react-router-dom";
import { Insights } from "@mui/icons-material";
import VerticalDivider from "../../../../common/components/Divider/VerticalDivider";
import { useFetchOrgInitDataService } from "../../../org/services/useFetchOrgInitDataService";
import Title from "../../../../common/components/Title/Title";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";

const ProjectView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  useRegisterHeader(HeaderRegistryEnum.projectView, []);
  // The service is being called to get updated insights data, as of this moment that information is not
  // saved in states
  const { isLoading } = useFetchOrgInitDataService(false);
  const projectsList = useAppSelector((state) => state.org.init.list);
  const hasProjects = projectsList.length > 0;

  const defaultBorder = `5px solid ${theme.palette.brightCardBackground.default}`;

  return (
    <>
      <Title>Project view</Title>
      {!isLoading && hasProjects && (
        <Grid container spacing={3} style={{ padding: "20px" }}>
          {projectsList.map(({ project, boards }, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card
                onClick={() =>
                  navigate(
                    `/${project.abbr}/board/${project.defaultBoardRef}/holistic`
                  )
                }
                style={{
                  cursor: "pointer",
                  color: theme.palette.brightCardBackground.main,
                  backgroundColor: theme.palette.brightCardBackground.paper,
                  borderTop: defaultBorder,
                  borderLeft: defaultBorder,
                  borderRight: defaultBorder,
                  borderBottom: `2px solid ${theme.palette.info.main}`,
                }}
                sx={{
                  "& .MuiCardContent-root:last-child": {
                    paddingBottom: "5px",
                  },
                }}
              >
                <CardActionArea>
                  <CardContent sx={{ p: 1 }}>
                    <Box display="flex" gap={0} flexDirection="column">
                      <Typography
                        variant="body1"
                        style={{ color: theme.palette.text.primary }}
                      >
                        {project.name}
                      </Typography>
                      <Typography variant="caption" fontWeight="bold">
                        #{project.abbr}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent sx={{ p: 0 }}>
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{ padding: "0 10px" }}
                    >
                      <Insights fontSize="small" />
                      <Typography variant="overline">
                        Boards <strong>{boards.length}</strong>
                      </Typography>
                      <VerticalDivider
                        sx={{ height: "3px", margin: "auto 0" }}
                      />
                      <Typography variant="overline">
                        Tasks <strong>{project.tasksCount}</strong>
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {isLoading && <Loader />}
      {!isLoading && !hasProjects && <Navigate to={"/"} replace={true} />}
    </>
  );
};

export default ProjectView;
