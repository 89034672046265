import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { customFieldActions } from "../../slices/customFieldSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  TypeEnum,
  typeLabels,
  typeOptions,
} from "../../../../entities/columns/customField/TypeEnum";
import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { useEffect } from "react";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { useUpdateCustomFieldService } from "../../services/useUpdateCustomFieldService";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import AddNewOptions from "../AddNewOptions/AddNewOptions";

const updatedOptionsPrefix = "updatedOptions.";

const UpdateForm = ({ onCancel, customField }: TUpdateFormProps) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.project.projectList);
  const form = useForm<TUpdateFormAttributes>({
    name: customField.name,
    // Set inside useEffect when projects are loaded
    projectIds: [],
    isRequired: customField.isRequired,
    isActive: customField.isActive,
    isFilterable: customField.isFilterable,
    // updatedOptions
    ...(customField.type === TypeEnum.OPTIONS && customField.data?.options
      ? customField.data.options.reduce(
          (obj, item) => ({
            ...obj,
            [`${updatedOptionsPrefix}${item.id}`]: item.label,
          }),
          {}
        )
      : {}),
    addNewOption: "",
    newOptions: [],
    // Should not be changed, must be disabled:
    isMultiple: customField.isMultiple,
    type: { id: customField.type, label: typeLabels[customField.type] },
  });
  const { isLoading, dispatch: dispatchUpdate } = useUpdateCustomFieldService({
    customFieldId: customField.id,
  });

  // Setting projectIds value to the form when projects list is loaded
  useEffect(() => {
    if (projects.length) {
      form.set({
        ...form.data!,
        projectIds: projectUtil.list(
          projects.filter((project) =>
            customField.projectIds?.includes(project.id as number)
          )
        ),
      });
    }
  }, [projects.length]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    // Grabbing back the updated options from the form data by object key prefix
    const updatedOptions = Object.keys(form.data ?? {})
      .filter((key) => key.startsWith(updatedOptionsPrefix))
      .map((key) => ({
        id: key.split(".")[1],
        label: form.data?.[key] ?? "",
      }));

    dispatchUpdate({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        name: data.name,
        projectIds: data.projectIds?.length
          ? data.projectIds.map((item) => item.id)
          : null,
        isRequired: data.isRequired,
        isMultiple: data.isMultiple,
        isFilterable: data.isFilterable,
        isActive: data.isActive,
        updatedOptions: updatedOptions.length ? updatedOptions : null,
        newOptions: data.newOptions?.length ? data.newOptions : null,
      })),
    })
      .then(() => {
        dispatch(customFieldActions.closeUpdateModal());
        // Raising "record updated event"
        dispatch(customFieldActions.recordUpdated());
        dispatch(
          systemNotificationActions.open({
            message: "Custom Field updated successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Update
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" showRequired />

      <Autocomplete
        form={form}
        name="projectIds"
        label={!form.data?.projectIds?.length ? "All projects" : "Projects"}
        multiple
        disabled={!projects.length}
        options={projectUtil.list(projects)}
      />

      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={<Checkbox form={form} name="isRequired" />}
          label={<Typography variant="body2">Required</Typography>}
        />
      </Box>

      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={<Checkbox form={form} name="isActive" />}
          label={<Typography variant="body2">Active</Typography>}
        />
      </Box>

      <Tooltip
        title={
          <Typography variant="body2">
            The type cannot be changed after creation. It is blocked to ensure
            data consistency for this field.
          </Typography>
        }
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        arrow
      >
        <Box>
          <Autocomplete
            form={form}
            name="type"
            label="Type"
            showRequired
            disabled
            options={typeOptions}
          />
        </Box>
      </Tooltip>

      {customField.type === TypeEnum.OPTIONS && (
        <>
          <Box display="flex" alignItems="center">
            <FormControl
              disabled={customField.isMultiple}
              error={form.hasError("isMultiple")}
            >
              <FormControlLabel
                control={<Checkbox form={form} name="isMultiple" value="" />}
                label={<Typography variant="body2">Allow Multiple</Typography>}
              />
              {<FormHelperText>{form.getError("isMultiple")}</FormHelperText>}
            </FormControl>
          </Box>

          <Box display="flex" alignItems="center">
            <FormControl error={form.hasError("isFilterable")}>
              <FormControlLabel
                control={<Checkbox form={form} name="isFilterable" />}
                label={<Typography variant="body2">Is Filterable</Typography>}
              />
              {<FormHelperText>{form.getError("isFilterable")}</FormHelperText>}
            </FormControl>
          </Box>

          <Box>
            <InputLabel>Current Options *</InputLabel>
            <Typography
              sx={{ color: "warning.main" }}
              variant="caption"
              component="p"
              mb={1.5}
            >
              You can only rename the existing options
            </Typography>
            <Stack spacing={2} direction="column">
              {customField.data?.options.map((option, index) => (
                <TextField
                  form={form}
                  key={option.id}
                  name={`${updatedOptionsPrefix}${option.id}`}
                  label=""
                  showRequired
                  error={form.hasError(`updatedOptions.${index}.label`)}
                  helperText={
                    form.hasError(`updatedOptions.${index}.label`)
                      ? form.getError(`updatedOptions.${index}.label`)
                      : undefined
                  }
                />
              ))}
            </Stack>
          </Box>

          <AddNewOptions
            form={form}
            label="New Options"
            attributeName="newOptions"
            addOptionName="addNewOption"
          />
        </>
      )}
    </Form>
  );
};

export default UpdateForm;
