import { TFinancialDataStoreFormProps } from "./types/TFinancialDataStoreFormProps";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { useFetchFinancialDataService } from "../../services/useFetchFinancialDataService";
import { useEffect } from "react";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TFinancialDataStoreFormAttributes } from "./types/TFinancialDataStoreFormAttributes";
import { enumUtil } from "../../../../common/utils/enum";
import {
  getRatePeriodLabelForRate,
  RatePeriodEnum,
} from "../../../../entities/columns/user/financialData/RatePeriodEnum";
import { CurrencyEnum } from "../../../../entities/columns/user/financialData/CurrencyEnum";
import { TListResource } from "../../../../common/types/TListResource";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import Form from "../../../../common/components/Form/Form";
import Loader from "../../../../common/containers/Loader/Loader";
import { useStoreFinancialDataService } from "../../services/useStoreFinancialDataService";
import { TFinancialDataStoreFormAttributesTransformed } from "./types/TFinancialDataStoreFormAttributesTransformed";
import { teamActions } from "../../slices/teamSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { Typography, useTheme } from "@mui/material";
import { Lock } from "@mui/icons-material";

const ratePeriodOptions = enumUtil.toList(
  RatePeriodEnum
) as TListResource<string>;
const currencyOptions = enumUtil.toList(CurrencyEnum) as TListResource<string>;

const FinancialDataStoreForm = ({
  userId,
  onCancel,
}: TFinancialDataStoreFormProps) => {
  const dispatch = useAppDispatch();

  const { isLoading: isFetchingFinancialData, data: financialDataResource } =
    useFetchFinancialDataService(userId);
  const {
    isLoading: isStoringFinancialData,
    dispatch: dispatchStoreFinancialData,
  } = useStoreFinancialDataService(userId);

  const form = useForm<TFinancialDataStoreFormAttributes>({
    "financialData.ratePeriod": null,
    "financialData.currency": null,
    "financialData.rate": "",
  });
  const theme = useTheme();
  useEffect(() => {
    if (!isFetchingFinancialData && !!financialDataResource.financialData) {
      form.changeDefaultData({
        "financialData.ratePeriod":
          ratePeriodOptions.find(
            (option) =>
              option.id === financialDataResource.financialData?.ratePeriod
          ) ?? null,
        "financialData.currency":
          currencyOptions.find(
            (option) =>
              option.id === financialDataResource.financialData?.currency
          ) ?? null,
        "financialData.rate": financialDataResource.financialData?.rate ?? "",
      });
    }
  }, [isFetchingFinancialData]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchStoreFinancialData({
      body: form.getTransformed<TFinancialDataStoreFormAttributesTransformed>(
        (data) => ({
          financialData: {
            ratePeriod: data["financialData.ratePeriod"]?.id ?? null,
            currency: data["financialData.currency"]?.id ?? null,
            rate: data["financialData.rate"],
          },
        })
      ),
    })
      .then(() => {
        dispatch(teamActions.closeFinancialDataModal());
        dispatch(
          systemNotificationActions.open({
            message: "User Financial Data is saved successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <>
      {isFetchingFinancialData && <Loader />}
      <Form
        onSubmit={submitHandler}
        actionElement={
          <>
            <Button
              onClick={onCancel}
              disabled={isFetchingFinancialData || isStoringFinancialData}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={
                isFetchingFinancialData ||
                isStoringFinancialData ||
                !form.isDirty()
              }
              variant="contained"
              type="submit"
              loading={isFetchingFinancialData || isStoringFinancialData}
            >
              Save
            </Button>
          </>
        }
      >
        <Typography pb={3} color={theme.palette.success.main}>
          <Lock sx={{ position: "relative", top: "4px" }} fontSize="small" />{" "}
          This information is stored encrypted in our databases
        </Typography>

        <Autocomplete
          form={form}
          name="financialData.ratePeriod"
          label="Pay Period"
          showRequired
          options={ratePeriodOptions}
          disabled={isFetchingFinancialData}
        />

        <TextField
          form={form}
          name="financialData.rate"
          label={getRatePeriodLabelForRate(
            form.data?.["financialData.ratePeriod"]?.label
          )}
          showRequired
          disabled={isFetchingFinancialData}
        />

        <Autocomplete
          form={form}
          name="financialData.currency"
          label="Currency"
          showRequired
          options={currencyOptions}
          disabled={isFetchingFinancialData}
        />
      </Form>
    </>
  );
};

export default FinancialDataStoreForm;
