import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IDescription } from "../../../../entities/IDescription";
import { useDescriptionService } from "../../services/useDescriptionService";
import DescriptionEditor from "./DescriptionEditor";
import { TDescriptionProps } from "./types/TDescriptionProps";
import { TFnHandlerParams } from "./types/TFnHandlerParams";
import { useStateManager } from "../../../../common/modules/editor";
import ShortcutProTip from "../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { aiSentinelActions } from "../../../aiSentinel/slices/aiSentinelSlice";
import useAiHandleSendUserMessage from "../../../aiSentinel/containers/hooks/useAiHandleSendUserMessage";
import { string } from "../../../../common/utils/string";
import AiSentinelIcon from "../../../aiSentinel/containers/AiSentinel/AiSentinelIcon";
import DescriptionContent from "./DescriptionContent";

const Description = (props: TDescriptionProps) => {
  const [expanded, setExpanded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    html,
    setEditorState,
    editorRequested,
    editorReady,
    requestEditorHandler,
    immediateRequestEditorHandler,
    initEditorHandler,
    closeEditorHandler,
  } = useStateManager();
  const { handleSendUserMessage } = useAiHandleSendUserMessage();

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.d,
    callback: () => {
      immediateRequestEditorHandler();
      ref.current?.scrollIntoView({ behavior: "smooth" });
    },
  });

  const serviceProps = {
    entityType: props.entityType,
    entityId: props.entityId,
  };
  const { isLoading, dispatch: dispatchFetchDescription } =
    useDescriptionService({
      ...serviceProps,
      method: RequestMethodEnum.GET,
    });
  const manageService = useDescriptionService({
    ...serviceProps,
    method: RequestMethodEnum.POST,
  });

  useEffect(() => {
    dispatchFetchDescription()
      .then((resource: IDescription) => {
        resource?.html && setEditorState({ html: resource.html });
      })
      .catch(() => {});
  }, [props.entityType, props.entityId]);

  const saveHandler = (handlerParams: TFnHandlerParams) => {
    closeEditorHandler();
    setEditorState(handlerParams);
    setExpanded(true);

    props.onSave?.(handlerParams);
  };

  return (
    <Grid
      container
      item
      bgcolor={theme.palette.taskView.mainContentBackground}
      borderRadius="12px"
      mb="8px"
      p={props.isMobileView && editorReady ? "0 0 16px" : "16px"}
    >
      <Grid ref={ref} container flexDirection="column" gap="12px">
        {(!editorRequested || !editorReady) && (
          <>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  color={theme.palette.taskView.textSecondaryColor}
                  fontSize="15px"
                  fontWeight={500}
                >
                  Description
                </Typography>
              </Grid>
              {html && (props.showAiBreakdown ?? true) && (
                <Grid item>
                  <Box
                    bgcolor={theme.palette.taskView.aiButtonBackground}
                    border="1px solid rgba(0, 0, 0, 0.10)"
                    borderRadius="6px"
                    color="#363C4C"
                    fontSize="12px"
                    p="4px 6px"
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(aiSentinelActions.open());
                      handleSendUserMessage(string.fromHtml(html));
                    }}
                  >
                    Breakdown with
                    <AiSentinelIcon
                      displayText
                      aiIcon={theme.palette.taskView.descriptionAiIcon}
                      border="none"
                      borderRadius="0"
                      padding="0"
                      size={18.1}
                      textProps={{ fontSize: "12px", fontWeight: "normal" }}
                      sx={{ gap: "4px" }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item>
              <Divider
                sx={{
                  backgroundColor: theme.palette.taskView.dividerBackground,
                  border: "none",
                  height: "1px",
                }}
              />
            </Grid>

            <Grid item width="100%">
              <DescriptionContent
                editorReady={editorReady}
                editorRequested={editorRequested}
                expanded={expanded}
                html={html}
                isLoading={isLoading}
                requestEditorHandler={requestEditorHandler}
                setExpanded={setExpanded}
              />
            </Grid>

            <Grid item>
              <ShortcutProTip
                shortcutKeyString={KeyboardShortcutEnum.d}
                description="update description"
                sx={{
                  color: theme.palette.taskView.labelColor,
                }}
                size="medium"
              />
            </Grid>
          </>
        )}

        {editorRequested && (
          <Grid item>
            <DescriptionEditor
              html={html}
              style={{ display: editorReady ? undefined : "none" }}
              entityType={props.entityType}
              entityId={props.entityId}
              onSave={saveHandler}
              onCancel={closeEditorHandler}
              onInit={initEditorHandler}
              saveService={manageService}
              isMobileView={props.isMobileView}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Description;
