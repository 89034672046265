import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../common/hooks/redux";

export const useTaskSegmentPatchService = () => {
  const dispatch = useAppDispatch();

  return {
    ...useHttpClientDispatchable({
      // /task/segment/{taskSegmentId}/shift
      // /task/segment/{taskSegmentId}/resize
      // /task/segment/{taskSegmentId}/resizeByEndDate
      // /task/segment/{taskSegmentId}/changeEstimation
      // /task/segment/{taskSegmentId}/shift/withData
      url: `/task/segment/`,
      method: RequestMethodEnum.PATCH,
    }),
    errorHandler: (error: THttpClientError) => {
      error.status === 406 &&
        dispatch(
          systemNotificationActions.open({
            message: error.data.message,
            variant: "warning",
          })
        );
      error.status !== 406 &&
        dispatch(
          systemNotificationActions.open({
            message: "Something went wrong, please try again.",
            variant: "error",
          })
        );
    },
  };
};
