import classes from "./TaskHorizontal.module.scss";
import StateIndicator from "../State/StateIndicator";
import { TTaskHorizontalProps } from "./types/TTaskHorizontalProps";
import StateIcon from "../State/StateIcon";
import { TaskHelper } from "../../helpers/TaskHelper";
import { useTheme } from "@mui/material";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import Resizer from "../Resizer/Resizer";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";

const TaskHorizontal = forwardRef(
  (props: TTaskHorizontalProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [parentNode, setParentNode] = useState<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const { openTaskViewHandler } = useTaskViewHandler({
      taskRef: props.task.ref,
      segmentNumber: props.task.number,
    });
    const sizeXs = TaskHelper.sizeXs(props.size!);

    useEffect(() => {
      setParentNode(containerRef.current);
    }, [containerRef.current]);

    return (
      <div
        ref={containerRef}
        className={`${props.className} ${classes.container ?? ""}`}
        style={{
          ...{
            top: props.positionY ? props.positionY : undefined,
            left: props.positionX,
            width: props.size,
          },
          ...props.style,
        }}
        onDragStart={props.onDragStart}
      >
        <div
          onClick={openTaskViewHandler}
          ref={ref}
          className={`${props.contentClassName} ${classes.content ?? ""}`}
          style={props.contentStyle}
        >
          {!sizeXs && (
            <StateIndicator
              className={classes.state}
              state={props.task.state}
              statusType={props.task.statusType}
              status={props.task.status}
            />
          )}
          <StateIcon
            className={
              !sizeXs ? classes["state-icon"] : classes["state-icon-xs"]
            }
            state={props.task.state}
            statusType={props.task.statusType}
            status={props.task.status}
          />
          <div>{props.children}</div>
        </div>
        {props.resizable && (
          <Resizer parentNode={parentNode} {...props.resizerConfig} />
        )}
      </div>
    );
  }
);

export default TaskHorizontal;
