import { TTeamState } from "./types/TTeamState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserResource } from "../interfaces/IUserResource";

const initialState: TTeamState = {
  invitation: {
    displayModal: false,
    email: null,
  },
  financialData: {
    showModal: false,
    userId: undefined,
  },
  virtualUser: {
    displayModal: false,
  },
  list: [],
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    openInvitationModal: (state, action: PayloadAction<string | undefined>) => {
      state.invitation.displayModal = true;
      if (action.payload) {
        state.invitation.email = action.payload;
      }
    },
    closeInvitationModal: (state) => {
      state.invitation.email = null;
      state.invitation.displayModal = false;
    },
    openFinancialDataModal: (state, action: PayloadAction<number>) => {
      state.financialData.showModal = true;
      state.financialData.userId = action.payload;
    },
    closeFinancialDataModal: (state) => {
      state.financialData.showModal = false;
      state.financialData.userId = undefined;
    },
    openCreateVirtualUserModal: (state) => {
      state.virtualUser.displayModal = true;
    },
    closeCreateVirtualUserModal: (state) => {
      state.virtualUser.displayModal = false;
    },
    setList: (state, action: PayloadAction<IUserResource[]>) => {
      state.list = action.payload;
    },
    removeFromList: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter(
        (item) => item.identifier !== action.payload
      );
    },
    addToList: (state, action: PayloadAction<IUserResource>) => {
      state.list = [action.payload, ...state.list];
    },
  },
});

export const teamReducer = slice.reducer;

export const teamActions = slice.actions;
