import { ConfirmationDialog } from "../../../../common/modules/materialUI";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { customFieldActions } from "../../slices/customFieldSlice";
import { useDeleteCustomFieldService } from "../../services/useDeleteCustomFieldService";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";

const DeleteConfirmationDialog = () => {
  const { showConfirmation, deletableRecord } = useAppSelector(
    (state) => state.customField.delete
  );
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchDelete } = useDeleteCustomFieldService({
    customFieldId: deletableRecord?.id ?? 0,
  });

  const closeConfirmationHandler = () =>
    dispatch(customFieldActions.closeDeleteConfirmation());

  const successHandler = () => {
    dispatch(customFieldActions.closeDeleteConfirmation());

    dispatchDelete()
      .then(() => {
        // Raising "record updated event"
        dispatch(customFieldActions.recordDeleted());
        dispatch(
          systemNotificationActions.open({
            message: "Custom Field deleted successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          dispatch(
            systemNotificationActions.open({
              message: `The custom field "${deletableRecord?.name}" is currently in use and cannot be deleted. However, you have the option to inactivate it instead.`,
              variant: "error",
            })
          );
        }
      });
  };

  return (
    <ConfirmationDialog
      open={showConfirmation}
      title="Delete Custom Field"
      content={`Are you sure you want to delete the custom field "${deletableRecord?.name}"? Please note that a custom field can only be deleted if it has not been used.`}
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteConfirmationDialog;
