import classes from "./UserLane.module.scss";
import { TUserLaneProps } from "./types/TUserLaneProps";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import moment from "moment";
import { user } from "../../../../../../common/utils/user";
import { OfftimeHorizontal } from "../../../../../../common/components/Offtime";
import { useTheme } from "@mui/material";
import Task from "../Task/Task";
import { CUSTOM_DRAG_LAYER_WIDTH } from "../Task/TaskCustomDragLayer";
import Overlay from "./Overlay";
import DropTargetContainer from "../DropTarget/DropTargetContainer";
import OccupiedSpace from "../../../../../../common/components/OccupiedSpace/OccupiedSpace";
import { taskUtil } from "../../../../../../common/utils/taskUtil";
import Event from "../../../../../../common/components/Event/Event";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";

const POSITION_SHIFT = 5;
export const USER_LANE_HEIGHT = 110;

const UserLane = (props: TUserLaneProps) => {
  const theme = useTheme();
  const laneData = useAppSelector((state) =>
    state.holisticView.data?.userLaneData.find(
      (userLaneData) => (userLaneData.user?.id ?? 0) === props.user.id
    )
  );

  const showShiftingProgress = useAppSelector(
    (state) => state.holisticViewTask.showShiftingProgress
  );
  const dropData = useAppSelector((state) => state.holisticViewTask.dropData);

  const userId = props.user.id;

  return (
    <div
      className={classes.container}
      style={{ width: props.width, minHeight: `${USER_LANE_HEIGHT}px` }}
    >
      <div
        className={classes.user}
        style={{
          backgroundColor: theme.palette.cardsBackground.paper,
          color: props.isSelected
            ? theme.palette.primary.main
            : theme.palette.cardsBackground.main,
          fontWeight: props.isSelected ? "700" : "inherit",
        }}
      >
        <div>{user.shortName(props.user)}</div>
      </div>
      <DropTargetContainer userId={userId} {...props} />
      <div
        className={classes["task-lane"]}
        style={{ backgroundColor: theme.palette.cardsBackground.default }}
      >
        <Overlay />
        {/*This is a custom task that will be displayed while shift process is loading*/}
        {showShiftingProgress && dropData?.taskLaneIndex === userId ? (
          <Task
            style={{ zIndex: 21 }}
            taskLaneIndex={userId}
            variant="large"
            task={dropData.task}
            size={CUSTOM_DRAG_LAYER_WIDTH}
            positionX={dropData.coords.x}
            positionY={dropData.coords.y}
          />
        ) : null}
        {laneData?.tasks?.map((task, _index) => {
          const xSize = pointConverter.pointToXSizeInPixels(task.tsPoints);
          // This will force tasks to re-render on user lane update, even if service sent same data
          const suffix = laneData.keySuffix ? "-" + laneData.keySuffix : "";
          const key = `task-${task.segmentId}${suffix}`;

          if (task.internalType === InternalTypeEnum.EVENT) {
            return (
              <Event
                name={task.name}
                key={key}
                size={xSize - POSITION_SHIFT}
                time={moment(task.startDate)}
                positionX={pointConverter.intervalToXPositionInPixels(
                  props.minDate,
                  moment(task.startDate)
                )}
              />
            );
          }

          return (
            <Task
              taskLaneIndex={userId}
              variant={xSize > 75 ? "large" : "small"}
              key={key}
              task={task}
              size={xSize - POSITION_SHIFT}
              positionX={pointConverter.intervalToXPositionInPixels(
                props.minDate,
                moment(task.startDate)
              )}
              splitDirection={task.splitDirection}
            />
          );
        })}
        {laneData?.offtimes?.map((offtime, _index) => (
          <OfftimeHorizontal
            key={`offtime-${userId}-${offtime.id}`}
            offtime={offtime}
            size={
              pointConverter.pointToXSizeInPixels(offtime.tsPoints) -
              POSITION_SHIFT
            }
            position={pointConverter.intervalToXPositionInPixels(
              props.minDate,
              moment(offtime.startDate)
            )}
          />
        ))}
        {laneData?.occupiedSpaces?.map((occupiedSpace, _index) => {
          const secondaryDescription = occupiedSpace.fromAnotherProject
            ? "#another project"
            : "#another board";

          return (
            <OccupiedSpace
              link={taskUtil.urlByRef(
                occupiedSpace.key,
                occupiedSpace.hasSplits ? occupiedSpace.number : undefined
              )}
              key={`occupiedSpace-${userId}-${occupiedSpace.key}-${occupiedSpace.number}`}
              taskRef={`${occupiedSpace.key}${
                occupiedSpace.hasSplits ? " #" + occupiedSpace.number : ""
              }`}
              mainDescription=""
              secondaryDescription={secondaryDescription}
              secondaryDescriptionShort={secondaryDescription}
              size={
                pointConverter.pointToXSizeInPixels(occupiedSpace.tsPoints) -
                POSITION_SHIFT
              }
              positionX={pointConverter.intervalToXPositionInPixels(
                props.minDate,
                moment(occupiedSpace.startDate)
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UserLane;
