import classes from "./Task.module.scss";
import taskHorizontalClasses from "../TaskHorizontal/TaskHorizontal.module.scss";
import { StateHelper } from "../State/helpers/StateHelper";
import StateIndicator from "../State/StateIndicator";
import StateIcon from "../State/StateIcon";
import { useAppSelector } from "../../../../hooks/redux";
import { TTaskProps } from "./types/TTaskProps";
import { Grid, useTheme } from "@mui/material";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import TaskMenu from "../Menu/TaskMenu";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { Tooltip } from "../../../../modules/materialUI";
import { link } from "../../../../utils/link";
import { useOrgProjectData } from "../../../../../modules/org/hooks/useOrgProjectData";

const Task = (props: TTaskProps) => {
  const theme = useTheme();
  const { task } = props;
  const { openTaskViewHandler } = useTaskViewHandler({
    taskRef: task.ref,
    segmentNumber: task.number,
  });

  const taskType = useGetTaskType({
    taskTypeId: task.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: task.taskPriorityId,
  });
  const board = task.boardRef
    ? useAppSelector((state) =>
        state.org.init.list
          .find((data) => data.project.ref === task.projectRef)
          ?.boards.find((board) => board.ref === task.boardRef)
      )
    : undefined;

  const project = useOrgProjectData().findProjectByRef(task.projectRef)
    ?.project!;

  const pushAsideClass = StateHelper.willDisplayState(
    task.statusType,
    task.state
  )
    ? classes["push-aside"]
    : "";

  return (
    <>
      <div
        className={classes.container}
        style={{
          backgroundColor: theme.palette.brightCardBackground.default,
          boxShadow: theme.palette.brightCardShadowColor.primary,
          color: theme.palette.brightCardBackground.main,
          width: props.width,
        }}
        onClick={openTaskViewHandler}
      >
        <div
          className={classes.content}
          style={{ backgroundColor: theme.palette.brightCardBackground.paper }}
        >
          <StateIndicator
            className={taskHorizontalClasses.state}
            state={task.state}
            statusType={task.statusType}
            status={task.status}
          />
          <StateIcon
            className={taskHorizontalClasses["state-icon"]}
            state={task.state}
            statusType={task.statusType}
            status={task.status}
          />
          <div>
            <div className={classes.topInfo}>
              <NavLink
                to={taskUtil.url(task)}
                className={`${classes.ref} ${classes.left}`}
                onClick={link.anchorClickHandler}
                style={{ color: theme.palette.brightCardBackground.main }}
              >
                {task.ref}
                {task.hasSplits ? ` #${task.number}` : ""}
              </NavLink>
              {board && (
                <NavLink
                  to={`/${project.abbr}/board/${board!.ref}`}
                  className={`${classes.boardName} ${classes.right}`}
                  onClick={(event) => event.stopPropagation()}
                >
                  # {board?.name ?? task.boardRef}
                </NavLink>
              )}
            </div>

            <div className={`${classes.name} ${pushAsideClass}`}>
              {task.name}
            </div>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={0.5}
            >
              {taskPriority && (
                <Grid item>
                  <Tooltip title={taskPriority.name}>
                    <img
                      src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                      alt={taskPriority.name}
                      width={16}
                      height={16}
                      style={{ display: "flex" }}
                    />
                  </Tooltip>
                </Grid>
              )}

              {taskType && (
                <Grid item>
                  <Tooltip title={taskType.name}>
                    <img
                      src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                      alt={taskType.name}
                      width={16}
                      height={16}
                      style={{ display: "flex" }}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                <div className={classes.metadata}>
                  <TaskMenu
                    taskRef={task.ref!}
                    taskId={task.taskId}
                    taskInternalType={task.internalType}
                    projectRef={task.projectRef}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
