import { TListItemComponentProps } from "./types/TListItemComponentProps";
import classes from "./ListItem.module.css";
// @TODO - Remove the dependency from Navigation and have self-replacement inside this components folder
import List from "../../../containers/Layout/App/Navigation/common/List/List";
import Link from "./Link";
import ExpandableIcon from "../../Icon/Expandable/ExpandableIcon";

const ListItem = (props: TListItemComponentProps) => {
  return (
    <li className={`${classes.li} ${props.className ?? ""}`}>
      <Link
        path={props.path}
        onClick={props.onClick}
        onNavigate={props.onNavigate}
        navigateToPath={props.navigateToPath ?? true}
        title={props.name}
      >
        {props.icon && (
          <span className={classes["icon-container"]}>{props.icon}</span>
        )}
        {props.name}
        <ExpandableIcon
          display={props.isExpandable}
          direction={props.isExpanded ? "down" : "right"}
          className={classes["expandable-icon"]}
        />
      </Link>
      {props.isExpandable && props.isExpanded && <List items={props.items} />}
    </li>
  );
};

export default ListItem;
