import { Box, Typography } from "@mui/material";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import { AddTask, MultilineChart } from "@mui/icons-material";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";
import { TTaskIdProp } from "../../../../../../common/modules/taskView/containers/types/TTaskIdProp";

type TProps = {
  taskInternalType: InternalTypeEnum;
} & TTaskIdProp;

const ConvertInternalTypeMenuItem = ({ taskId, taskInternalType }: TProps) => {
  const dispatch = useAppDispatch();
  const targetInternalType =
    taskInternalType === InternalTypeEnum.TASK
      ? InternalTypeEnum.SPIKE
      : InternalTypeEnum.TASK;

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={() =>
        dispatch(
          taskViewActions.openConvertInternalTypeConfirmation({
            taskId,
            taskInternalType,
            targetInternalType,
          })
        )
      }
    >
      {targetInternalType === InternalTypeEnum.SPIKE ? (
        <MultilineChart fontWeight="400" />
      ) : (
        <AddTask fontWeight="400" />
      )}
      <Typography variant="subtitle2" fontWeight="400">
        Convert to a {targetInternalType}
      </Typography>
    </Box>
  );
};

export default ConvertInternalTypeMenuItem;
