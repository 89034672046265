import { TTaskProps } from "./types/TTaskProps";
import classes from "./TaskHorizontalLarge.module.scss";
import TaskHorizontal from "./TaskHorizontal";
import { StateHelper } from "../State/helpers/StateHelper";
import { Grid, useTheme } from "@mui/material";
import React, { ForwardedRef, forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import TaskMenu from "../Menu/TaskMenu";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import { Tooltip } from "../../../../modules/materialUI";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { link } from "../../../../utils/link";
import TaskCost from "./TaskCost";
import { pointConverter } from "../../../../utils/pointConverter";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import { spikeStyleConfig } from "./spikeStyleConfig";
import { currencyUtil } from "../../../../utils/currencyUtil";
import { number } from "../../../../utils/number";

const TaskHorizontalLarge = forwardRef(
  (props: TTaskProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const { task } = props;
    const pushAsideClass = StateHelper.willDisplayState(
      task.statusType,
      task.state
    )
      ? classes["push-aside"]
      : "";
    const taskType = useGetTaskType({
      taskTypeId: task.taskTypeId,
    });
    const taskPriority = useGetTaskPriority({
      taskPriorityId: task.taskPriorityId,
    });

    const isSpike = task.internalType === InternalTypeEnum.SPIKE;
    const spikeStyles = spikeStyleConfig(theme);

    const showCostOnTheCard =
      props.size > pointConverter.workingHoursToPixels(6);
    const shouldDisplayCost = props.displayCostPerItem && !!task.cost;

    return (
      <TaskHorizontal
        {...props}
        ref={ref}
        className={`${props.className} ${classes.container}`}
        contentClassName={classes.content}
        style={{
          ...{
            backgroundColor: theme.palette.brightCardBackground.default,
            boxShadow: theme.palette.brightCardShadowColor.primary,
            borderLeft: taskUtil.isSplitLeft(props.splitDirection)
              ? theme.palette.cardSplitBorder.main
              : undefined,
            borderRight: taskUtil.isSplitRight(props.splitDirection)
              ? theme.palette.cardSplitBorder.main
              : undefined,
          },
          ...props.style,
          ...(isSpike ? spikeStyles.containerStyle : {}),
        }}
        contentStyle={{
          backgroundColor: theme.palette.brightCardBackground.paper,
          color: theme.palette.brightCardBackground.main,
          ...(isSpike ? spikeStyles.contentStyle : {}),
        }}
      >
        <div>
          <NavLink
            to={taskUtil.url(task)}
            className={classes.ref}
            onClick={link.anchorClickHandler}
            style={{
              color: "inherit",
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            {isSpike && (
              <Tooltip title={InternalTypeEnum.SPIKE}>
                <img
                  src={theme.palette.spike.xsImg}
                  alt=""
                  style={{
                    width: "11px",
                    height: "11px",
                  }}
                />
              </Tooltip>
            )}
            {task.ref}
            {task.hasSplits ? ` #${task.number}` : ""}
          </NavLink>
        </div>
        {showCostOnTheCard && shouldDisplayCost && (
          <TaskCost cost={task.cost!} size={Math.floor(props.size / 3.5)} />
        )}
        <div
          className={`${classes.name} ${pushAsideClass}`}
          style={isSpike ? spikeStyles.nameStyle : {}}
        >
          {task.name}
        </div>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={0.5}
          sx={{ minWidth: "80px" }}
        >
          {taskPriority && (
            <Grid item>
              <Tooltip title={taskPriority.name}>
                <img
                  src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                  alt={taskPriority.name}
                  width={16}
                  height={16}
                  style={{ display: "flex" }}
                />
              </Tooltip>
            </Grid>
          )}

          {taskType && (
            <Grid item>
              <Tooltip title={taskType.name}>
                <img
                  src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                  alt={taskType.name}
                  width={16}
                  height={16}
                  style={{ display: "flex" }}
                />
              </Tooltip>
            </Grid>
          )}

          <Grid item sx={{ pl: "0 !important" }}>
            <div className={classes.metadata}>
              <TaskMenu
                taskRef={task.ref!}
                taskId={task.taskId}
                taskInternalType={task.internalType}
                projectRef={task.projectRef}
                sx={isSpike ? spikeStyles.moreOptionsStyle : {}}
                indentSubheader={false}
                listSubheader={
                  !showCostOnTheCard && shouldDisplayCost ? (
                    <Grid container alignItems="center" my={0.5} minWidth={140}>
                      <Grid item xs={6}>
                        {!showCostOnTheCard &&
                          shouldDisplayCost &&
                          `${currencyUtil.getSymbol(
                            task.cost!.costCurrency
                          )}${number.formatAsPrice(task.cost!.cost)}`}
                      </Grid>
                    </Grid>
                  ) : undefined
                }
              />
            </div>
          </Grid>
        </Grid>
      </TaskHorizontal>
    );
  }
);
export default TaskHorizontalLarge;
