export enum EventEnum {
  ON_BEFORE_TASK_CREATED,
  ON_TASK_CREATED,
  ON_TASK_MAIN_DATA_UPDATED,
  ON_TASK_STATUS_UPDATED,
  ON_TASK_ASSIGNED_TO_UPDATED,
  ON_PROJECT_CREATED,
  ON_INVITATION_SENT,
  ON_TASK_RESIZE_IN_TASK_VIEW,
  ON_TASK_TYPE_CREATED,
  ON_TASK_TYPE_UPDATED,
  ON_TASK_TYPE_DELETED,
  ON_TASK_PRIORITY_CREATED,
  ON_TASK_PRIORITY_UPDATED,
  ON_TASK_PRIORITY_DELETED,
  ON_BOARD_FILTERS_CHANGED,
  ON_SPRINT_CREATED,
  ON_TASK_VIEW_MODE_CHANGED,
  // TBacklogItemCreatedEvent
  ON_BACKLOG_ITEM_CREATED,
  // TBacklogItemUpdatedEvent
  ON_BACKLOG_ITEM_UPDATED,
  // TBacklogItemDeletedEvent
  ON_BACKLOG_ITEM_DELETED,
  ON_OBJECTIVE_MAIN_DATA_UPDATED,
  ON_INITIATIVE_CREATED,
  ON_INITIATIVE_UPDATED,
  ON_INITIATIVE_DELETED,
  ON_REWARDFUL_READY,
}
