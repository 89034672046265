import { IconButton, Tooltip } from "@mui/material";
import { TTaskMenuProps } from "./types/TTaskMenuProps";
import Menu from "../../../../containers/Menu/Menu";
import TaskLinkMenuItem from "./TaskLinkMenuItem";
import { TMenuItemProps } from "../../../../containers/Menu/types/TMenuItemProps";
import { MoreHoriz } from "@mui/icons-material";
import classes from "./TaskMenu.module.css";
import MoveToBacklogMenuItem from "../../../../../modules/task/modules/view/containers/MoveToBacklog/MoveToBacklogMenuItem";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import ConvertInternalTypeMenuItem from "../../../../../modules/task/modules/view/containers/ConvertInternalType/ConvertInternalTypeMenuItem";

const TaskMenu = (props: TTaskMenuProps) => {
  return (
    <Menu
      stretchOnMobile={false}
      listSubheader={props.listSubheader}
      indentSubheader={props.indentSubheader}
      button={(clickHandler) => (
        <Tooltip title="More options">
          <IconButton
            className={props.hidden ? classes.hidden : undefined}
            onClick={clickHandler}
          >
            {props.icon ?? (
              <MoreHoriz
                sx={{ fontSize: props.fontSize ?? "1rem", ...props.sx }}
              />
            )}
          </IconButton>
        </Tooltip>
      )}
      menuItems={
        [
          TaskLinkMenuItem(props),
          ...(props.taskInternalType === InternalTypeEnum.TASK
            ? [
                MoveToBacklogMenuItem({
                  taskId: props.taskId,
                }),
              ]
            : []),
          ...([InternalTypeEnum.TASK, InternalTypeEnum.SPIKE].includes(
            props.taskInternalType
          )
            ? [
                ConvertInternalTypeMenuItem({
                  taskId: props.taskId,
                  taskInternalType: props.taskInternalType,
                }),
              ]
            : []),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default TaskMenu;
