import { Button as MuiButton } from "@mui/material";
import { TButtonProps } from "./types/TButtonProps";
import Loading from "../../../../components/Icon/Loading/Loading";

const Button = (props: TButtonProps) => {
  const { loading, ...buttonProps } = props;

  return (
    <MuiButton
      {...buttonProps}
      startIcon={!loading ? props.startIcon : <Loading />}
    />
  );
};

export default Button;
