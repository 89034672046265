import { StateEnum } from "../../../../../../entities/enums/StateEnum";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";

export const StateHelper = {
  getLabel: (statusType: AggStatusEnum, status: string, state?: StateEnum) =>
    statusType === AggStatusEnum.DONE ? status : state ?? status,
  /**
   * Get whether the status or the state information that must be displayed
   * as a priority
   */
  getPrecedence: (statusType: AggStatusEnum, state?: StateEnum) =>
    statusType === AggStatusEnum.DONE ? statusType : state ?? statusType,

  /**
   * The state is displayed for any other option than the OPEN at the moment
   */
  willDisplayState: (statusType: AggStatusEnum, state?: StateEnum) =>
    StateHelper.getPrecedence(statusType, state) !== AggStatusEnum.TO_DO,
};
