export enum KeyboardShortcutEnum {
  t = "t", // create a task
  b = "b", // create a backlog item
  s = "s", // TaskView - change task summary
  c = "c", // TaskView - add a comment
  d = "d", // TaskView - change description
  o = "o", // objective view - add new objective
  i = "i", // objective view - add new initiative
  l = "l", // objective view - link existing initiative
}
