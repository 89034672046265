import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { TIntegrationCardProps } from "./types/TIntegrationCardProps";
import { useFetchInstallResourceService } from "../services/useFetchInstallResourceService";
import { IConfigureIntegrationResource } from "../interfaces/IConfigureIntegrationResource";
import { useConfigureIntegrationService } from "../services/useConfigureIntegrationService";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";
import { integrationActions } from "../slices/integrationSlice";
import { Button, ConfirmationButton } from "../../../common/modules/materialUI";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useTheme } from "@mui/material";
import { useFetchUninstallResourceService } from "../services/useFetchUninstallResourceService";
import useWindow from "../../../common/hooks/useWindow";

const IntegrationCard = (props: TIntegrationCardProps) => {
  const { openNewTab } = useWindow();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const orgIntegration = useAppSelector(
    (state) => state.integration.orgIntegrationList
  );
  const integrationInstalled = orgIntegration.some(
    (item) => item.integration === props.integration.integration
  );

  //Endpoint for integration install execute/redirect URL
  const {
    isLoading: isLoadingInstallResource,
    dispatch: dispatchFetchInstallResourceService,
  } = useFetchInstallResourceService({
    integration: props.integration.integration,
  });

  //Endpoint for integration uninstall execute/redirect URL
  const {
    isLoading: isLoadingUninstallResource,
    dispatch: dispatchFetchUninstallResourceService,
  } = useFetchUninstallResourceService({
    integration: props.integration.integration,
  });

  //Endpoint for installing integration
  const { isLoading: installing, dispatch: dispatchIntegrationInstallService } =
    useConfigureIntegrationService();

  //Endpoint for uninstalling integration
  const {
    isLoading: uninstalling,
    dispatch: dispatchIntegrationUninstallService,
  } = useConfigureIntegrationService();

  const isLoading =
    installing ||
    isLoadingInstallResource ||
    uninstalling ||
    isLoadingUninstallResource;

  const isActive = props.integration.name === "Slack";
  const manageIntegrationText = !isActive
    ? "Coming Shortly"
    : integrationInstalled
    ? "Uninstall"
    : "Install";
  const manageIntegrationLoadingText = integrationInstalled
    ? "Uninstalling"
    : "Installing";

  const manageIntegrationHandler = () => {
    if (integrationInstalled) {
      uninstallIntegrationHandler();
    } else {
      installIntegrationHandler();
    }
  };

  const uninstallIntegrationHandler = () => {
    dispatchFetchUninstallResourceService().then(
      (payload: IConfigureIntegrationResource) => {
        if (payload.redirect) {
          openNewTab(payload.url, true);
        } else {
          dispatchIntegrationUninstallService({ urlPath: payload.url })
            .then(() => {
              dispatch(
                integrationActions.removeOrgIntegration({
                  integration: props.integration.integration,
                })
              );
              integrationSuccessHandler();
            })
            .catch(() => {
              integrationErrorHandler();
            });
        }
      }
    );
  };

  const installIntegrationHandler = () => {
    dispatchFetchInstallResourceService().then(
      (payload: IConfigureIntegrationResource) => {
        if (!payload) {
          dispatch(
            systemNotificationActions.open({
              message: `The ${props.integration.integration} integration will be available for you soon.`,
              variant: "info",
            })
          );
        } else if (payload.redirect) {
          openNewTab(payload.url, true);
        } else {
          dispatchIntegrationInstallService({ urlPath: payload.url })
            .then(() => {
              integrationSuccessHandler();
            })
            .catch(() => {
              integrationErrorHandler();
            });
        }
      }
    );
  };

  const integrationSuccessHandler = () => {
    dispatch(
      systemNotificationActions.open({
        message: `${props.integration.integration} ${
          !integrationInstalled ? "installed" : "uninstalled"
        } successfully.`,
        variant: "success",
      })
    );
  };

  const integrationErrorHandler = () => {
    dispatch(
      systemNotificationActions.open({
        message: `The ${
          !integrationInstalled ? "installation" : "uninstallation"
        } of the ${
          props.integration.integration
        } integration has failed. Please try again.`,
        variant: "error",
      })
    );
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
      }}
    >
      <CardMedia
        sx={{
          width: 180,
          height: 70,
          margin: "auto",
        }}
        image={props.integration.banner[theme.palette.mode]}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.integration.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.integration.description}
        </Typography>
      </CardContent>
      <CardActions>
        <ConfirmationButton
          title={manageIntegrationText}
          content={`Are you sure you want to ${manageIntegrationText.toLowerCase()} ${
            props.integration.integration
          } integration?`}
          button={
            <Button
              size="small"
              disabled={!isActive || isLoading}
              loading={isLoading}
              type="submit"
            >
              {!isLoading
                ? manageIntegrationText
                : manageIntegrationLoadingText}
            </Button>
          }
          data={orgIntegration}
          successHandler={manageIntegrationHandler}
        />
      </CardActions>
    </Card>
  );
};

export default IntegrationCard;
