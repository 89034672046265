import { TBoardSettingsNavigationContentProps } from "./types/TBoardSettingsNavigationContentProps";
import { array } from "../../../../../../utils/array";
import classes from "../../common/Navigation.module.scss";
import List from "../../common/List/List";
import { ArrowBack } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../../../../modules/board/containers/Board/types/TBoardParams";
import BoardSettingsSection from "./BoardSettingsSection";
import { useAppSelector } from "../../../../../../hooks/redux";
import { Typography } from "@mui/material";

const BoardSettingsNavigationContent = ({
  matchesMax992Screen,
  pushContentClassName,
  navigationRequestedHandler,
}: TBoardSettingsNavigationContentProps) => {
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const projects = useAppSelector((state) => state.org.init.list);
  const board = projects
    .find((item) => item.project.abbr === projectAbbr)
    ?.boards.find((board) => board.ref === boardRef);

  return (
    <nav
      className={array.toClassName([
        classes.navigation,
        !matchesMax992Screen ? pushContentClassName : "",
      ])}
    >
      <List
        title={
          board?.name ? (
            <Typography variant="h5" sx={{ color: "primary.contrastText" }}>
              {board.name}
            </Typography>
          ) : (
            "Loading..."
          )
        }
        items={[
          {
            id: "boardSettingsBack",
            name: "Back",
            path: `/${projectAbbr}/board/${boardRef}/`,
            icon: <ArrowBack />,
          },
        ]}
      />
      <BoardSettingsSection
        onClick={navigationRequestedHandler}
        projectAbbr={projectAbbr}
        boardRef={boardRef}
      />
    </nav>
  );
};

export default BoardSettingsNavigationContent;
