import { Typography, useTheme } from "@mui/material";
import { Tooltip } from "../../../../modules/materialUI";
import { TStateIndicatorProps } from "./types/TStateIndicatorProps";
import {
  CheckCircle,
  Error,
  RunningWithErrors,
  Timelapse,
  Warning,
} from "@mui/icons-material";
import { StateEnum } from "../../../../../entities/enums/StateEnum";
import { StateHelper } from "./helpers/StateHelper";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";

const StateIcon = (props: TStateIndicatorProps) => {
  const label = StateHelper.getLabel(
    props.statusType,
    props.status,
    props.state
  );
  const precedence = StateHelper.getPrecedence(props.statusType, props.state);
  const willDisplayState = StateHelper.willDisplayState(
    props.statusType,
    props.state
  );
  const theme = useTheme();

  return (
    <Tooltip
      when={willDisplayState && !props.hideTooltip}
      placement={props.placement ?? "top"}
      title={<Typography variant="body2">{label}</Typography>}
      arrow
    >
      <div className={props.className} style={props.style}>
        {precedence === StateEnum.AT_RISK && (
          <Warning
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.risk.main }}
          />
        )}
        {precedence === StateEnum.AT_DANGER && (
          <Error
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.warning.main }}
          />
        )}
        {precedence === StateEnum.DELAYED && (
          <RunningWithErrors
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.error.main }}
          />
        )}
        {precedence === AggStatusEnum.IN_PROGRESS && (
          <Timelapse
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.info.main }}
          />
        )}
        {precedence === AggStatusEnum.DONE && (
          <CheckCircle
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.successLight.main }}
          />
        )}
        {!willDisplayState &&
          !!props.renderOnNoStateIcon &&
          props.renderOnNoStateIcon}
      </div>
    </Tooltip>
  );
};

export default StateIcon;
