import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { attachmentActions } from "../slices/attachmentSlice";
import { IAttachment } from "../../../entities/IAttachment";
import { useAppDispatch } from "../../../common/hooks/redux";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

export const useAttachmentsService = (props: {
  entityType: EntityTypeEnum;
  entityId: number;
}) => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/attachment/${props.entityType}/${props.entityId}/list`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IAttachment[]) =>
        dispatch(attachmentActions.setList(data)),
    },
  });
};
