import { createTheme } from "@mui/material";
import { env } from "../../utils/env";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    dropTargetBackground: {
      default: "#10957d",
      paper: "#353c4e",
    },
    text: {
      primary: "#353c4e",
    },
    primary: {
      main: "#278D76",
      contrastText: "#FEFEFE",
    },
    background: {
      default: "#f6f7fb",
      paper: "#ffffff",
    },
    brightCardBackground: {
      highlightColor: "#000000",
      default: "#F2F5F6",
      paper: "#ffffff",
      main: "#353c4e",
      contrastText: "#AEBDC1",
    },
    taskView: {
      mainBackground: "#F6F7FB",
      secondaryBackground: "#FDFDFE",
      mainContentBackground: "#EEF0F7",
      headerTitleBackground: "rgba(0, 0, 0, 0.05)",
      aiButtonBackground: "#ffffff",
      dividerBackground: "rgba(0, 0, 0, 0.10)",
      attachmentDividerBackground: "rgba(162, 162, 162, 0.10)",
      headerBorderBottomBackground: "rgba(0, 0, 0, 0.10)",
      dropContainerBackground: "rgba(226, 226, 226, 0.7)",
      editorExpandGradient:
        "linear-gradient(180deg, rgba(246, 247, 251, 0.00) 0%, #EEF0F7 72.56%)",
      textMainColor: "#353C4E",
      textSecondaryColor: "#4C5363",
      labelColor: "#676B77",
      quickActionsLinkColor: "#676B77",
      descriptionAiIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
    },
    brightCardShadowColor: {
      primary: "inset 0 0 10px #fff, 0 0 0 #fff",
      secondary: "inset 0 0 4px #fff, 0 0 0 #fff",
    },
    mutedCardBackground: {
      default: "#ebebeb",
      paper: "#f3f3f3",
      main: "#353c4e",
    },
    cardsBackground: {
      default: "#eff1f5",
      paper: "#ffffff",
      main: "#717173",
    },
    cardSplitBorder: {
      main: "2px dashed #b7b7b7",
    },
    inputPallet: {
      main: "312",
    },
    loginPallet: {
      main: "#ffffff",
    },
    pickerPallet: {
      primary: "#000000",
      secondary: "#5b6272",
    },
    boardSideViewShadow: {
      main: "#d6d7db -47px -20px 20px -40px inset",
    },
    error: {
      main: "#D33939",
      dark: "#aa3434",
    },
    risk: {
      main: "#ffaf42",
    },
    warning: {
      main: "#ff8155",
    },
    info: {
      main: "#15a6a9",
    },
    success: {
      main: "#278D76",
    },
    successLight: {
      main: "#50c878",
    },
    dataGrid: {
      borderColor: "#e2e7ed",
      footerBackground: "#fff",
      headerBackground: "#fff",
      rowOddBackground: "#fdfdff",
      rowEvenBackground: "#fafafd",
      rowHoverBackground: "#8197bd21",
    },
    landingPageCard: {
      default: "#EDEEF2",
      paper: "#ffffff",
    },
    event: {
      background: "rgb(208 207 249 / 8%)",
      color: "#5b6272",
      backgroundImgSrc: `${env.cdnUrl}/components/event/event-lines-light.png`,
      shadow: "#ffffff 0px 0px 20px inset",
      icon: `${env.cdnUrl}/components/event/calendar-light-theme.svg`,
    },
    offtime: {
      backgroundImgSrc: `${env.cdnUrl}/components/offtime/offtime-line.png`,
    },
    occupied: {
      color: "#cacbce",
      backgroundImgSrc: `${env.cdnUrl}/components/occupied-space/occupied-lines-light-theme-v3.png`,
      shadow: "0px 0px 4px 0px rgba(255,255,255,1)",
    },
    spike: {
      backgroundColor: "#feeba6",
      color: "#353c4e",
      border: "2px solid #fde17b",
      backgroundImgSrc: `${env.cdnUrl}/components/spike/spike-icon-dark.png`,
      xsImg: `${env.cdnUrl}/components/spike/xsspike-icon-dark.png`,
    },
    aiSentinel: {
      aiChatBackgroundColor: "#f0f3f5",
      userChatBackgroundColor: "#d4d7e0",
      chatGeneralBackgroundColor: "#e6e8ed",
      headerBackgroundColor: "#f5f7f8",
      fabIconBackgroundColor: "#ffffff",
      headerIconBackgroundColor: "#353b44",
      chatRequiredFieldsColor: "#73797F",
      chatRequiredFieldsBackgroundColor: "#E9EAEE",
      regularIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
      headerIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-light.png`,
    },
    statusTypeSchema: {
      toDo: { color: "#b6c7d9" },
      inProgress: { color: "#82cdd3" },
      done: { color: "#80d3b3" },
      textColor: "#353c4e",
      buttonBackground: "#e2e6ea",
    },
    nonWorkday: {
      timelineColor: "#17C29C",
      timelineFontWeight: 500,
    },
    sprint: {
      main: "#ACDFD3",
    },
    avatar: {
      color: "#F6F7FB",
      border: "3px solid #579085",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 1px 1px -1px rgba(53,60,78,0.2), 0px 1px 1px 0px rgba(53,60,78,0.14), 0px 1px 3px 0px rgba(53,60,78,0.12)",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          // Required for showing Autocomplete options inside AI Sentinel when any Dialog is opened
          zIndex: 1500,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
  },
});
