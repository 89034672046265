import { useState } from "react";
import { Grid } from "@mui/material";
import {
  Autocomplete,
  Button,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import { IntegrationEnum } from "../../../../entities/enums/IntegrationEnum";
import { IListResource } from "../../../../interfaces/IListResource";
import SlackChannelForm from "./Slack/SlackChannelForm";
import { useFetchIntegrationInstalledListService } from "../../../integrations/services/useFetchIntegrationInstalledListService";
import { TListItem } from "../../../../common/types/TListItem";

const AddIntegration = () => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IListResource | null>(null);
  const { data, isLoading } = useFetchIntegrationInstalledListService();
  const hasInstalledIntegrations = data?.length;

  const formDisplayHandler = () => {
    setSelectedIntegration(null);
    setFormOpen((prevState) => !prevState);
  };

  const onChangeHandler = (value: IListResource) => {
    setSelectedIntegration(value ?? null);
  };

  return (
    <>
      <Grid container>
        <Tooltip
          title={
            "There are not any installed integrations in your organization"
          }
          when={!isLoading && !hasInstalledIntegrations}
        >
          <Grid item xs={12} md={6}>
            {!formOpen && (
              <Button
                variant="text"
                startIcon={<Add />}
                onClick={formDisplayHandler}
                loading={isLoading}
                disabled={isLoading || !hasInstalledIntegrations}
              >
                Add integration
              </Button>
            )}
          </Grid>
        </Tooltip>
      </Grid>

      {formOpen && (
        <>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ mb: "1rem" }}>
              <Autocomplete
                value={selectedIntegration}
                name="integration"
                label="Integration"
                options={data.map(
                  (item) =>
                    ({
                      id: item.integration,
                      label: item.integration,
                    } as TListItem<string>)
                )}
                showRequired
                onChange={(e, value) => onChangeHandler(value)}
              />
            </Grid>
          </Grid>

          {selectedIntegration?.id === IntegrationEnum.SLACK && (
            <SlackChannelForm formDisplayHandler={formDisplayHandler} />
          )}

          {!selectedIntegration && (
            <Grid container>
              <Grid item xs={12} md={6}>
                <Button onClick={formDisplayHandler} variant="outlined">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default AddIntegration;
