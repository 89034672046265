import { TUserLaneProps } from "../UserLane/types/TUserLaneProps";
import { TUserIdProp } from "./types/TUserIdProp";
import classes from "./DropTargetContainer.module.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { useDrop } from "react-dnd";
import { DRAG_TYPE_TASK_HORIZONTAL } from "../Task/Task";
import { TTaskProps } from "../Task/types/TTaskProps";
import { TDropResult } from "./types/TDropResult";
import { useEffect } from "react";
import {
  getDayByCoords,
  lastContainerRef,
} from "../../hooks/useVirtualizationHelper";
import { holisticViewTaskActions } from "../../slices/holisticViewTaskSlice";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { string } from "../../../../../../common/utils/string";
import moment from "moment/moment";
import {
  FORMAT_LABEL,
  FORMAT_RAW_DATE_ONLY,
} from "../../../../../../common/utils/date";
import { useTheme } from "@mui/material";
import useDragOverHelper from "../../../../hooks/useDragOverHelper";

const buildLabel = (date: string, hours: number) => {
  const dateObject = moment(date);

  return `${dateObject.date()} ${dateObject.format(
    FORMAT_LABEL
  )}, ${string.getOrdinal(hours)} hour`;
};

export default function DropTargetContainer(
  props: TUserLaneProps & TUserIdProp
) {
  const { isOver, dragOverHandlers } = useDragOverHelper();
  const usersSectionWidth = 25;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    currentDateStartXCoord,
    currentXCoord,
    currentHours,
    currentDate,
    dragPosition,
  } = useAppSelector((state) => state.holisticViewTask.dropContainerData);

  const workingHours = useAppSelector(
    (state) => state.holisticView.workingHours
  );
  const isDragging = useAppSelector(
    (state) => state.holisticViewTask.isDragging
  );

  const [, drop] = useDrop(
    () => ({
      accept: DRAG_TYPE_TASK_HORIZONTAL,
      hover: (item: TTaskProps, monitor) => {
        dispatch(
          holisticViewTaskActions.setDropContainerCoords(
            monitor.getClientOffset()!
          )
        );
      },
      drop: (item: TTaskProps, monitor) => {
        return {
          taskLaneIndex: props.userId,
          userId: props.userId !== 0 ? props.userId : null,
          timeTick: {
            dropXCoord: currentXCoord,
            hour: currentHours,
            date: moment(currentDate)!.format(FORMAT_RAW_DATE_ONLY),
            label: buildLabel(currentDate!, currentHours!),
          },
          item: item,
          workingHours: workingHours,
        } as TDropResult;
      },
    }),
    [currentXCoord, currentHours, currentDate]
  );

  const stepInPx = pointConverter.oneWorkingHourInPixels(workingHours);

  useEffect(() => {
    // Calculate the current position inside the lane based on the scroll left of the container,
    // so we know what is the container left offset and right offset, mouse drag coordinates
    // are current mouse coordinates and to achieve this we can sum to the scroll left the
    // diff of current x and container offset left to receive correct internal coordinates
    if (!dragPosition || !lastContainerRef) {
      return;
    }

    const scrollLeft = lastContainerRef.scrollLeft;

    const shiftInWindow =
      dragPosition.x - lastContainerRef.offsetLeft - usersSectionWidth;
    const currentX = scrollLeft + shiftInWindow;

    const coordData = getDayByCoords(currentX);
    if (!coordData) {
      return;
    }

    const { day, coord } = coordData;
    const hours = Math.ceil((currentX - coord) / stepInPx);

    dispatch(
      holisticViewTaskActions.setDropContainerData({
        currentXCoord: currentX,
        currentDateStartXCoord: coord,
        currentHours: hours,
        currentDate: day,
      })
    );
    dispatch(holisticViewTaskActions.updateDragSummary(buildLabel(day, hours)));
  }, [dragPosition?.x]);

  if (!isDragging) {
    return null;
  }

  // The background overlay is opened by another component <Overlay />
  return (
    <>
      {currentDateStartXCoord && isOver && (
        <div
          style={{
            position: "absolute",
            // By this we are moving the overlay to the left side for two days because we
            // display extra 4 hours, means 2 hours on both sides
            // Check line length: workingHours + 4
            left: currentDateStartXCoord - 2 * stepInPx + usersSectionWidth,
            top: 0,
            display: "flex",
            zIndex: 100,
          }}
        >
          {Array.from({ length: workingHours + 4 }).map((value, index) => (
            <div
              key={`${index}`}
              style={{
                width: "1px",
                // Index is doing -2 since we have shifted the overlay to -2 days, check above
                // parent component absolute positioning
                height: index + 1 - 2 === currentHours ? "10px" : "3px",
                borderLeft: `1px solid ${theme.palette.dropTargetBackground.paper}`,
                marginRight: stepInPx,
              }}
            />
          ))}
        </div>
      )}
      <div {...dragOverHandlers} ref={drop} className={classes.container} />
    </>
  );
}
