import { TUploadIconButtonProps } from "./types/TUploadIconButtonProps";
import { Add } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import { Box } from "@mui/material";
import { useRef } from "react";

const UploadIconButton = ({ onFileLoad }: TUploadIconButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fileSelectHandler = () => {
    fileInputRef.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      onFileLoad(selectedFile);
      // Clear the input value
      fileInputRef.current!.value = "";
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Button variant="text" startIcon={<Add />} onClick={fileSelectHandler}>
        Upload Icon
      </Button>
      <input
        type="file"
        accept="image/jpeg, image/png"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={fileChangeHandler}
      />
    </Box>
  );
};

export default UploadIconButton;
