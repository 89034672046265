import { useAppSelector } from "../../../hooks/redux";
import { AbilityEnum } from "../enums/AbilityEnum";

export const useAccessControl = () => {
  const { abilities, accessibleRoles } = useAppSelector(
    (state) => state.accessControl
  );

  return {
    acLoaded: !!abilities,
    can: (ability: AbilityEnum) => abilities?.includes(ability),
    accessibleRoles,
  };
};
