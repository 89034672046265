import { TMenuItemProps } from "../types/TMenuItemProps";
import { ReactElement } from "react";

export const menuItem = {
  make: (
    component: ReactElement,
    handler?: (event?: React.MouseEvent) => void,
    when?: boolean
  ) =>
    ({
      component,
      handler,
      when,
    } as TMenuItemProps),
};
