import React from "react";
import { Grid, Typography } from "@mui/material";
import Type from "./Type";
import Title from "../../../../common/components/Title/Title";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import { TBoardTypePickerProps } from "./types/TBoardTypePickerProps";

const BoardTypePicker = (props: TBoardTypePickerProps) => {
  return (
    <>
      <Title>Pick Your Board</Title>
      <Grid container spacing={5}>
        <Grid item>
          <Type
            {...props}
            type={BoardTypeEnum.INITIATIVE_MANAGEMENT}
            imgSrc={`${process.env.CDN_URL}/icons/gantt-chart-v2-150x150.png`}
            name={<Typography variant="h5">Holistic Board</Typography>}
            desc={
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "text.secondary" }}
              >
                Experience the power of visualization through an innovative{" "}
                <strong>Holistic View</strong> of your tasks, while staying
                up-to-date with real-time monitoring and sensor technology.
                Access various views, including Kanban, List and others, to
                optimize and empower your workflow.
              </Typography>
            }
          />
        </Grid>
        <Grid item>
          <Type
            {...props}
            type={BoardTypeEnum.WORK_AUTOMATION}
            imgSrc={`${process.env.CDN_URL}/icons/project-automation-v2-150x150.png`}
            name={<Typography variant="h5">Work Automation</Typography>}
            desc={
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "text.secondary" }}
              >
                Maximize your productivity with our advanced automation
                features, including incoming hooks, auto-assignment, and
                sensor-based assignee selection. Stay on top of your workload
                with an innovative <strong>Holistic View</strong> of your tasks.
                Experience the power of technology.
              </Typography>
            }
            comingSoon
          />
        </Grid>
        <Grid item>
          <Type
            {...props}
            type={BoardTypeEnum.INTERACTIVE_REMINDERS}
            imgSrc={`${process.env.CDN_URL}/icons/innovation-v2-150x150.png`}
            name={<Typography variant="h5">Interactive Reminders</Typography>}
            desc={
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "text.secondary" }}
              >
                Experience the ultimate convenience of interactive reminders,
                allowing you to stay on top of your schedule with ease.{" "}
                <strong>Delegate reminders</strong> to others effortlessly, and
                rely on real-time monitoring and sensors to ensure nothing falls
                through the cracks. Boost your productivity.
              </Typography>
            }
            comingSoon
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BoardTypePicker;
