import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Button } from "../../../common/modules/materialUI";
import { TOffDaysProps } from "./types/TOffDaysProps";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useUpdateOffDaysService } from "../services/useUpdateOffDaysService";
import useRegisterHeader from "../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../common/modules/registry/enums/HeaderRegistryEnum";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import OffDayButton from "./OffDayButton";

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const OffDays = ({
  disableButton,
  initialOffDayData,
  offDayData,
  offDayDataId,
  setOffDayData,
}: TOffDaysProps) => {
  const dispatch = useAppDispatch();
  const { projectAbbr } = useParams();

  const { isLoading, dispatch: dispatchUpdateOffDaysService } =
    useUpdateOffDaysService();

  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, [projectAbbr]);

  const offDayUpdateButtonDisabled =
    offDayData?.length === initialOffDayData?.current.length &&
    !offDayData?.some(
      (item, index) => item !== initialOffDayData.current.at(index)
    );

  const offDayUpdateHandler = () => {
    dispatchUpdateOffDaysService({
      body: { days: offDayData },
      urlPath: `/${offDayDataId}`,
    })
      .then(() => {
        initialOffDayData.current = offDayData;
        dispatch(
          systemNotificationActions.open({
            message: "Non working days updated",
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          systemNotificationActions.open({
            message: "Unable to update non working days, please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={4} mb={3}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {weekDays.map((weekDay, index, array) => {
          const offDayIndex = index === array.length - 1 ? 0 : index + 1;

          return (
            <OffDayButton
              key={offDayIndex}
              weekDay={weekDay}
              offDayIndex={offDayIndex}
              disabled={disableButton}
              setOffDayData={setOffDayData}
              selected={offDayData?.some((offDay) => offDay === offDayIndex)}
            />
          );
        })}
      </Box>

      <Button
        disabled={offDayUpdateButtonDisabled || isLoading}
        variant="contained"
        type="submit"
        loading={isLoading}
        onClick={offDayUpdateHandler}
      >
        Save
      </Button>
    </Box>
  );
};

export default OffDays;
