import ExpandableIcon from "../../../../../common/components/Icon/Expandable/ExpandableIcon";
import Box from "@mui/material/Box";
import Dropdown from "../../../../../common/modules/materialUI/components/Dropdown/Dropdown";
import { useCallback, useEffect, useState } from "react";
import { TEditableColumnProps } from "./types/TEditableColumnProps";
import { SelectChangeEvent } from "@mui/material";
import { jwt } from "../../../../../common/utils/jwt";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { usePatchUserService } from "../../../services/usePatchUserService";
import { string } from "../../../../../common/utils/string";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { useFetchOrgInitDataService } from "../../../../org/services/useFetchOrgInitDataService";

const EditableColumn = ({
  patchServiceName,
  gridRenderCellParams,
  list,
  name,
}: TEditableColumnProps) => {
  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true);
  const [value, setValue] = useState<string>(gridRenderCellParams.value);
  const { dispatch: dispatchPatchUser } = usePatchUserService();
  const dispatch = useAppDispatch();
  const [editable, setEditable] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);

  const me = jwt.parse()!.id === gridRenderCellParams.row.id;

  const submitHandler = (e: SelectChangeEvent) => {
    const newValue = e.target.value;
    const oldValue = value;
    setValue(newValue);
    closeDropdownHandler();
    dispatchPatchUser({
      urlPath: `${gridRenderCellParams.row.id}/${patchServiceName}`,
      body: { [name]: newValue },
    })
      .then(() => {
        dispatchFetchOrgInitDataService();
        dispatch(
          systemNotificationActions.open({
            message: `${string.capitalize(name)} changed`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        setValue(oldValue);
        error.status === 406
          ? dispatch(
              systemNotificationActions.open({
                message: error.data.message,
                variant: "error",
              })
            )
          : dispatch(
              systemNotificationActions.open({
                message: `Error while changing ${name}. Please try again.`,
                variant: "error",
              })
            );
      });
  };

  const closeDropdownHandler = () => {
    setEditable(false);
  };

  const renderDropdownHandler = () => {
    if (me) {
      dispatch(
        systemNotificationActions.open({
          message: `You cannot change your own ${name}`,
          variant: "warning",
        })
      );
      return;
    }

    setOpen(true);
    setEditable(true);
  };

  const escapeHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeDropdownHandler();
    }
  }, []);

  useEffect(() => {
    // On escape close when editable we need to set editable to be false
    if (editable) {
      window.addEventListener("keydown", escapeHandler);
    } else {
      window.removeEventListener("keydown", escapeHandler);
    }
  }, [editable]);

  return (
    <>
      {!editable && (
        <Box display="flex" alignItems="center" onClick={renderDropdownHandler}>
          <span style={{ cursor: "pointer" }}>{value}</span>
          {!me && <ExpandableIcon direction="down" size="md" />}
        </Box>
      )}
      {editable && (
        <Dropdown
          onChange={submitHandler}
          value={value}
          options={list}
          autoFocus
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onBlur={closeDropdownHandler}
        />
      )}
    </>
  );
};

export default EditableColumn;
