import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../Board/types/TBoardParams";
import { BoardViewEnum } from "../../../boardView/enums/BoardViewEnum";
import useNavigateBoard from "../../../boardView/hooks/useNavigateBoard";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { userActions } from "../../../user/slices/userSlice";
import Loader from "../../../../common/containers/Loader/Loader";
import { enumUtil } from "../../../../common/utils/enum";
import Title from "../../../../common/components/Title/Title";
import { VIEW_PICKER_ID } from "../../../boardView/containers/ViewPicker/ViewPicker";
import HolisticView from "../../../boardView/modules/holisticView/containers/HolisticView/HolisticView";
import KanbanView from "../../../boardView/modules/kanbanView/containers/KanbanView";
import ListView from "../../../boardView/modules/listView/containers/ListView";
import NotFound from "../../../../common/components/Page/NotFound/NotFound";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { taskActions } from "../../../task/slices/taskSlice";
import { boardQueryActions } from "../../../boardView/slices/boardQuerySlice";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import MoveToBacklogConfirmation from "../../../task/modules/view/containers/MoveToBacklog/MoveToBacklogConfirmation";
import { Grid } from "@mui/material";
import Sorting from "../../../boardView/containers/Filter/ControlSection/Sorting/Sorting";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";
import AddAssigneesDialog from "../../../task/modules/view/containers/AddAssignee/AddAssigneesDialog";
import DeleteSegmentConfirmation from "../../../task/modules/view/containers/DeleteSegment/DeleteSegmentConfirmation";
import ConvertInternalTypeConfirmation from "../../../task/modules/view/containers/ConvertInternalType/ConvertInternalTypeConfirmation";
import Progress from "./Progress";

const HolisticBoard = (props: TBoardDefaultProps) => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const { boardView: boardViewParam } = useParams<TBoardParams>();
  const navigate = useNavigateBoard();
  useRegisterHeader(HeaderRegistryEnum.board, [props.boardRef], {
    boardRef: props.boardRef,
    projectAbbr: props.projectAbbr,
  });
  const dispatch = useAppDispatch();

  const board = props.board!;

  // For now on quick task creation we will launch the task creation dialog, later to be prompt creation
  useKeyboardShortcut({
    key: KeyboardShortcutEnum.t,
    callback: () =>
      dispatch(taskActions.openCreationModal(InternalTypeEnum.TASK)),
  });

  useEffect(() => {
    if (boardViewParam) {
      return;
    }

    navigate(BoardViewEnum.HOLISTIC_VIEW);
  }, [boardViewParam]);

  useEffect(() => {
    return () => {
      // Reset user list when leaving the board
      dispatch(userActions.reset());
      dispatch(boardQueryActions.reset());
    };
  }, [props.boardRef]);

  const boardViewProps = { ...props, board };

  // Different views will be available here, e.g. Kanban, List...
  return (
    <>
      {!boardViewParam && <Loader />}
      {boardViewParam &&
        (enumUtil.includes(BoardViewEnum, boardViewParam) ? (
          <>
            <Grid container>
              <Grid item xs={8}>
                <Title subtitle={`${board.type} board`}># {board.name}</Title>
                <Progress board={board} />
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <div id="filters-top"></div>
                {boardViewParam !== BoardViewEnum.HOLISTIC_VIEW &&
                  matchesMax768Screen && <Sorting />}
              </Grid>
            </Grid>

            <div id={VIEW_PICKER_ID}></div>

            {boardViewParam === BoardViewEnum.HOLISTIC_VIEW && (
              <HolisticView {...boardViewProps} />
            )}

            {boardViewParam === BoardViewEnum.KANBAN_VIEW && (
              <KanbanView {...boardViewProps} />
            )}

            {boardViewParam === BoardViewEnum.LIST_VIEW && (
              <ListView {...boardViewProps} />
            )}

            <MoveToBacklogConfirmation isOnPage={false} />
            <ConvertInternalTypeConfirmation />
            <AddAssigneesDialog />
            <DeleteSegmentConfirmation isOnPage={false} />
          </>
        ) : (
          <NotFound />
        ))}
    </>
  );
};

export default HolisticBoard;
