import { TOfftimeHorizontalProps } from "./types/TOfftimeHorizontalProps";
import classes from "./OfftimeHorizontal.module.scss";
import { date, FORMAT_WITH_ORDINAL } from "../../utils/date";
import moment from "moment";
import { useTheme } from "@mui/material";
import { array } from "../../utils/array";

const OfftimeHorizontal = (props: TOfftimeHorizontalProps) => {
  const theme = useTheme();
  const startDate = moment(props.offtime.startDate);
  const endDate = moment(props.offtime.endDate);
  const days = date.intervalToDays(startDate, endDate, true, false);

  return (
    <div
      className={classes.container}
      style={{
        left: props.position,
        width: props.size,
        backgroundColor: theme.palette.brightCardBackground.default,
        boxShadow: theme.palette.brightCardShadowColor.primary,
      }}
    >
      <div
        className={array.toClassName([classes.content])}
        style={{
          backgroundColor: theme.palette.brightCardBackground.paper,
          color: theme.palette.brightCardBackground?.contrastText,
          backgroundImage: `url(${theme.palette.offtime.backgroundImgSrc})`,
        }}
      >
        <div className={classes.headline}>Off Time</div>
        {days > 1 && (
          <>
            <div>
              From {startDate.format(FORMAT_WITH_ORDINAL)} till{" "}
              {endDate.format(FORMAT_WITH_ORDINAL)}
            </div>
            <div>{days} days</div>
          </>
        )}
        {!(days > 1) && (
          <>
            <div>On {startDate.format(FORMAT_WITH_ORDINAL)}</div>
            <div>{days} day</div>
          </>
        )}
      </div>
    </div>
  );
};

export default OfftimeHorizontal;
