import Title from "../../../common/components/Title/Title";
import { Box } from "@mui/material";
import { Button } from "../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import { objectiveActions } from "../slices/objectiveSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import CreateModal from "./CreateObjective/CreateModal";
import useKeyboardShortcut from "../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import ShortcutProTip from "../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import ObjectiveGrid from "./ObjectiveGrid/ObjectiveGrid";

const ObjectivesPage = () => {
  const dispatch = useAppDispatch();

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.o,
    callback: () => addObjectiveHandler(),
  });

  const addObjectiveHandler = () =>
    dispatch(objectiveActions.openCreateModal());

  return (
    <>
      <Title>Objectives</Title>
      <Box mb={3}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={addObjectiveHandler}
        >
          Add Objective
        </Button>
        <ShortcutProTip
          shortcutKeyString={KeyboardShortcutEnum.o}
          description="add an objective"
        />
        <CreateModal />
      </Box>
      <ObjectiveGrid />
    </>
  );
};

export default ObjectivesPage;
