import { Typography, useTheme } from "@mui/material";
import { Tooltip } from "../../../../modules/materialUI";
import { TStateIndicatorProps } from "./types/TStateIndicatorProps";
import { StateHelper } from "./helpers/StateHelper";
import { StateEnum } from "../../../../../entities/enums/StateEnum";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";

const StateIndicator = (props: TStateIndicatorProps) => {
  const label = StateHelper.getLabel(
    props.statusType,
    props.status,
    props.state
  );
  const precedence = StateHelper.getPrecedence(props.statusType, props.state);
  const theme = useTheme();

  const map: { [key: string]: string } = {
    [StateEnum.AT_RISK]: theme.palette.risk.main,
    [StateEnum.AT_DANGER]: theme.palette.warning.main,
    [StateEnum.DELAYED]: theme.palette.error.main,
    [AggStatusEnum.IN_PROGRESS]: theme.palette.info.main,
    [AggStatusEnum.DONE]: theme.palette.successLight.main,
  };

  return map[precedence] ? (
    <Tooltip title={<Typography variant="body2">{label}</Typography>} arrow>
      <div
        className={props.className ?? ""}
        style={{ backgroundColor: map[precedence] }}
      />
    </Tooltip>
  ) : null;
};

export default StateIndicator;
