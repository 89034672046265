import { THeaderControlProps } from "./types/THeaderControlProps";
import CreateMonitor from "../../../monitor/containers/CreateMonitor/CreateMonitor";
import CreateTask from "../../../task/containers/CreateTask/CreateTask";
import classes from "./HeaderControl.module.css";
import HeaderSchema from "../../../../common/containers/Layout/App/Header/HeaderRegistry/HeaderSchema";
import BoardMenu from "../Menu/BoardMenu";

const HeaderControl = (props: THeaderControlProps) => {
  return (
    <HeaderSchema
      registerLeft={
        <div className={classes["header-control"]}>
          <CreateTask boardRef={props.boardRef} />
          <CreateMonitor boardRef={props.boardRef} />
        </div>
      }
      registerRight={
        <BoardMenu boardRef={props.boardRef} projectAbbr={props.projectAbbr} />
      }
    />
  );
};

export default HeaderControl;
