import { Box } from "@mui/material";
import { DataGrid } from "../../../../common/modules/materialUI";
import Loader from "../../../../common/containers/Loader/Loader";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { gridClasses } from "@mui/x-data-grid";
import { useFetchObjectiveListService } from "../../services/useFetchObjectiveListService";
import { Columns } from "./Columns";
import { objectiveActions } from "../../slices/objectiveSlice";
import DeleteConfirmationDialog from "../DeleteObjective/DeleteConfirmationDialog";
import ObjectiveViewModal from "../View/ObjectiveViewModal";
import { useEffect } from "react";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";

const ObjectiveGrid = () => {
  const dispatch = useAppDispatch();
  const objectiveList = useAppSelector((state) => state.objective.list);
  const { isLoading, dispatch: dispatchFetchObjectivesList } =
    useFetchObjectiveListService();

  useSubscribeEvent(EventEnum.ON_OBJECTIVE_MAIN_DATA_UPDATED, () => {
    dispatchFetchObjectivesList();
  });
  useSubscribeEvent(EventEnum.ON_INITIATIVE_CREATED, () => {
    dispatchFetchObjectivesList();
  });
  useSubscribeEvent(EventEnum.ON_INITIATIVE_DELETED, () => {
    dispatchFetchObjectivesList();
  });

  useEffect(() => {
    dispatchFetchObjectivesList();
  }, []);

  return (
    <Box>
      <DeleteConfirmationDialog />
      <ObjectiveViewModal />
      <DataGrid
        columns={Columns({
          onDeleteButtonClick: (e, objective) => {
            e.stopPropagation();
            dispatch(objectiveActions.openDeleteConfirmation(objective));
          },
        })}
        getRowHeight={() => "auto"}
        rows={objectiveList ?? []}
        disableRowSelectionOnClick
        onRowClick={(row) => {
          dispatch(
            objectiveActions.openViewModal({ objectiveRef: row.row.ref })
          );
        }}
        checkboxSelection={false}
        autoHeight={!objectiveList || !objectiveList.length}
        loading={objectiveList === null || isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `52px !important`,
            cursor: "pointer",
          },
        }}
      />
      {isLoading && <Loader />}
    </Box>
  );
};

export default ObjectiveGrid;
