import { Typography, useTheme } from "@mui/material";
import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { useUpdateUserLanes } from "../../../boardView/modules/holisticView/hooks/useUpdateUserLanes";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { ITask } from "../../../../entities/ITask";
import classes from "./EmptyView.module.scss";

const EmptyView = (props: TBoardDefaultProps) => {
  const theme = useTheme();
  const { dispatch: dispatchUpdateUserLane } = useUpdateUserLanes({
    projectAbbr: props.projectAbbr,
    boardRef: props.boardRef,
  });

  useSubscribeEvent(EventEnum.ON_TASK_CREATED, (task: ITask) => {
    dispatchUpdateUserLane();
  });

  return (
    <div className={`text-center ${classes.container}`}>
      <img
        src={`${process.env.CDN_URL}/icons/welcoming/${
          theme.palette.mode === "dark"
            ? "welcome-board-view-v2-dark.png"
            : "welcome-board-view-light.png"
        }`}
        alt=""
      />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Welcome to task management excellence!
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
        As you begin your journey, take a moment to add your first task and
        experience the satisfaction of productivity.
      </Typography>
    </div>
  );
};

export default EmptyView;
