import { useFetchIntegrationInstalledListService } from "../services/useFetchIntegrationInstalledListService";
import Loader from "../../../common/containers/Loader/Loader";
import { useEffect } from "react";
import IntegrationCard from "./IntegrationCard";
import { useFetchIntegrationMetadataService } from "../services/useFetchIntegrationMetadataService";
import { Grid } from "@mui/material";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import { IntegrationEnum } from "../../../entities/enums/IntegrationEnum";

const IntegrationList = () => {
  const dispatch = useAppDispatch();
  const url = new URL(window.location.href);
  const integration = url.searchParams.get("installed") ?? undefined;
  const { isLoading } = useFetchIntegrationInstalledListService();
  const { data: integrationData, isLoading: isIntegrationLoading } =
    useFetchIntegrationMetadataService({ installed: integration });

  useEffect(() => {
    if (integration) {
      url.searchParams.delete("installed");
      window.history.pushState({}, "", url);

      const msg =
        IntegrationEnum[
          integration?.toUpperCase() as keyof typeof IntegrationEnum
        ];
      dispatch(
        systemNotificationActions.open({
          message: `${msg} installed successfully.`,
          variant: "success",
        })
      );
    }
  }, [integration]);

  return (
    <>
      {(isLoading || isIntegrationLoading) && <Loader />}
      <Grid container spacing={5}>
        {!isLoading &&
          !isIntegrationLoading &&
          integrationData.map((item, index) => (
            <Grid item key={index}>
              <IntegrationCard integration={item} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default IntegrationList;
