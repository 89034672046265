import { TTooltipProps } from "./types/TTooltipProps";
import { Tooltip as MuiTooltip } from "@mui/material";

const Tooltip = (props: TTooltipProps) => {
  const { when, children, ...tooltipProps } = props;

  return when ?? true ? (
    <MuiTooltip placement="top" {...tooltipProps}>
      {children}
    </MuiTooltip>
  ) : (
    <>{children}</>
  );
};

export default Tooltip;
