import { TClassNameProp } from "../../../../types/TClassNameProp";
import classes from "./Content.module.scss";
import AppRoutes from "../Routes/AppRoutes";
import { useTheme } from "@mui/material";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import useWindowInnerHeight from "../../../../hooks/useWindowInnerHeight";
import { HEADER_HEIGHT } from "../Header/Header";
import { useAppSelector } from "../../../../hooks/redux";
import { useLocation } from "react-router-dom";

export const MAIN_CONTENT_AI_RENDERED_PADDING_BOTTOM = 96;

const Content = (props: TClassNameProp) => {
  const { pathname } = useLocation();
  const noPaddingPages = ["/task", "/backlog", "/objective/"];
  const windowHeight = useWindowInnerHeight();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const aiRendered = useAppSelector((state) => state.aiSentinel.aiRendered);
  const theme = useTheme();

  const paddingBottom = aiRendered
    ? `${MAIN_CONTENT_AI_RENDERED_PADDING_BOTTOM}px`
    : "";

  return (
    <div
      className={`${props.className} ${classes.content}`}
      style={{
        minHeight: windowHeight - HEADER_HEIGHT,
        backgroundColor: theme.palette.background.default,
        padding: !noPaddingPages.some((path) => pathname.startsWith(path))
          ? matchesMax576Screen
            ? `1rem 0.5rem ${paddingBottom}`
            : `1.8rem 1.8rem ${paddingBottom}`
          : 0,
      }}
    >
      <AppRoutes />
    </div>
  );
};

export default Content;
