import { Box, Typography } from "@mui/material";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import { Archive } from "@mui/icons-material";
import { TMoveToBacklogMenuItemProps } from "./types/TMoveToBacklogMenuItemProps";

const MoveToBacklogMenuItem = ({ taskId }: TMoveToBacklogMenuItemProps) => {
  const dispatch = useAppDispatch();

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={() =>
        dispatch(taskViewActions.openMoveToBacklogConfirmation({ taskId }))
      }
    >
      <Archive fontWeight="400" />
      <Typography variant="subtitle2" fontWeight="400">
        Move to backlog
      </Typography>
    </Box>
  );
};

export default MoveToBacklogMenuItem;
