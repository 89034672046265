import { currencyUtil } from "./currencyUtil";
import { CurrencyEnum } from "../../entities/columns/user/financialData/CurrencyEnum";

export const env = {
  appName: process.env.APP_NAME,
  cdnUrl: process.env.CDN_URL,
  siteUrl: process.env.SITE_URL,
  defaultCurrency: currencyUtil.getSymbol(CurrencyEnum.USD),
  phoneVerificationEnabled: process.env.PHONE_VERIFICATION_ENABLED === "true",
  msOrgRegistrationUrl: process.env.TS_MS_ORG_REGISTRATION_URL,
  liveChatUrl: process.env.LIVE_CHAT_URL,
};
