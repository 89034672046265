import { taskViewActions } from "../../slices/viewSlice";
import { AddAssigneesTypeEnum } from "../../slices/enums/AddAssigneesTypeEnum";
import { Add } from "@mui/icons-material";
import { Button } from "../../../../../../common/modules/materialUI";
import React from "react";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { ITask } from "../../../../../../entities/ITask";

type TProps = {
  taskId: ITask["id"];
};

export default function AddAssigneesButton({ taskId }: TProps) {
  const dispatch = useAppDispatch();

  return (
    <Button
      onClick={() =>
        dispatch(
          taskViewActions.openAddAssigneesDialog({
            taskId: taskId,
            type: AddAssigneesTypeEnum.ADD_ASSIGNEE,
          })
        )
      }
      size="small"
      sx={{
        minWidth: "55px",
        fontSize: "12px",
        textTransform: "capitalize",
      }}
    >
      <Add sx={{ fontSize: "inherit" }} /> Add
    </Button>
  );
}
