import { useFetchUserListService } from "../../../services/useFetchUserListService";
import { Box } from "@mui/material";
import Loader from "../../../../../common/containers/Loader/Loader";
import { Columns } from "./Columns";
import { useEffect } from "react";
import InviteMember from "../../Invitation/InviteMember";
import { TMembersProps } from "./types/TMembersProps";
import { DataGrid } from "../../../../../common/modules/materialUI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { teamActions } from "../../../slices/teamSlice";
import FinancialDataModal from "../../StoreFinancialData/FinancialDataModal";
import { useDropOrgInvitationService } from "../../../../user/services/useDropOrgInvitationService";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { IUserResource } from "../../../interfaces/IUserResource";
import { useFetchOrgInitDataService } from "../../../../org/services/useFetchOrgInitDataService";
import CreateVirtualUser from "../../VirtualUser/CreateVirtualUser";

const Members = (props: TMembersProps) => {
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchFetchUserList } =
    useFetchUserListService();

  const { dispatch: dispatchDropOrgInvitationService } =
    useDropOrgInvitationService();
  const data = useAppSelector((state) => state.team.list);

  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true);

  useEffect(() => {
    dispatchFetchUserList();
  }, []);

  const onDeleteButtonClickHandler = (user: IUserResource) => {
    dispatch(teamActions.removeFromList(user.identifier));
    dispatchDropOrgInvitationService({
      body: { email: user.email },
    })
      .then(() => {
        dispatchFetchOrgInitDataService();
        dispatch(
          systemNotificationActions.open({
            message: `Invitation dropped for ${user.email}`,
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(teamActions.addToList(user));
        dispatch(
          systemNotificationActions.open({
            message: `Failed to drop invitation for ${user.email}. Please try again.`,
            variant: "error",
          })
        );
      });
  };

  const onSendInvitationClickHandler = (user: IUserResource) => {
    dispatch(teamActions.openInvitationModal(user.email));
  };

  return (
    <>
      <Box sx={{ mt: 1, mb: 3 }} gap={1} display="flex" flexWrap="wrap">
        <InviteMember />
        <CreateVirtualUser />
      </Box>
      <Box sx={{ width: "100%" }}>
        {data && data.length > 0 && !isLoading && (
          <>
            <FinancialDataModal />

            <DataGrid
              rows={data}
              getRowId={(row) => row.identifier}
              columns={Columns({
                ...props,
                onFinancialDataButtonClick: (userId) =>
                  dispatch(teamActions.openFinancialDataModal(userId)),
                onDeleteButtonClick: onDeleteButtonClickHandler,
                onSendInvitation: onSendInvitationClickHandler,
              })}
              disableRowSelectionOnClick
              checkboxSelection={false}
            />
          </>
        )}
        {isLoading && <Loader />}
      </Box>
    </>
  );
};

export default Members;
