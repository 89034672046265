import Menu from "../../../../../../common/containers/Menu/Menu";
import { IconButton, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import Section from "../../../../containers/AddButton/Section";
import { AddTask } from "@mui/icons-material";
import ShortcutProTip from "../../../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import { KeyboardShortcutEnum } from "../../../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { backlogItemCreateActions } from "../../../../../backlog/modules/create/slices/backlogItemCreateSlice";

const AddButton = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const addBacklogItemClickHandler = () => {
    dispatch(backlogItemCreateActions.openCreationModal());
  };

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={true}
      button={(clickHandler) => {
        return (
          <IconButton
            aria-label="add"
            onClick={clickHandler}
            size="medium"
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            <AddIcon />
          </IconButton>
        );
      }}
      menuItems={[
        menuItem.make(
          <Section
            helperText={
              <ShortcutProTip
                shortcutKeyString={KeyboardShortcutEnum.t}
                description="add a backlog item"
              />
            }
            icon={<AddTask />}
            name="Add Backlog Item"
          />,
          addBacklogItemClickHandler
        ),
      ]}
    />
  );
};

export default AddButton;
